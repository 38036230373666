import { getClassNames } from '@websolutespa/bom-core';
import { useLabel } from '@websolutespa/bom-mixer-hooks';
import { IconLlmChevronRightCircle } from '@websolutespa/bom-mixer-icons';
import { ReactNode } from 'react';
import { LlmChunkCard, LlmChunkCardItem, LlmChunkCardThron, LlmChunkCardVideo } from '../../../types';
import { ThronPlayer } from '../ThronPlayer/thron-player';
import { VideoPlayer } from '../VideoPlayer/video-player';

export const CardWrapper = ({ children, ...props }: (LlmChunkCardItem | LlmChunkCard) & { children: ReactNode }) => {
  // const label = useLabel();
  const classNames = getClassNames('llm__card', `llm__card--${props.cardType}`);
  return (
    <div className={classNames}>
      {children}
    </div>
  );
};

export const Card = (props: LlmChunkCardItem | LlmChunkCard) => {
  const label = useLabel();
  return (
    <CardWrapper {...props}>
      {props.media.type === 'image' && props.cta.url && (
        <a className="llm__card-media" href={props.cta.url} target="_blank" rel="noreferrer" aria-label={props.cta.label || label('llm.tellMeMore')}>
          <div className="llm__card-asset">
            <img src={props.media.src} alt="" />
          </div>
        </a>
      )}
      {props.media.type === 'image' && !props.cta.url && (
        <div className="llm__card-media">
          <div className="llm__card-asset">
            <img src={props.media.src} alt="" />
          </div>
        </div>
      )}
      {props.media.type === 'video' && (
        <div className="llm__card-media">
          <div className="llm__card-asset">
            <VideoPlayer {...props as LlmChunkCardVideo} />
          </div>
        </div>
      )}
      {props.media.type === 'thron' && (
        <div className="llm__card-media">
          <div className="llm__card-asset">
            <ThronPlayer {...props as LlmChunkCardThron} />
          </div>
        </div>
      )}
      <div className="llm__card-content">
        {props.date && (
          <div className="llm__card-date">{props.date}</div>
        )}
        {props.title && (
          <div className="llm__card-title" dangerouslySetInnerHTML={{ __html: props.title }} />
        )}
        {props.abstract && (
          <div className="llm__card-abstract" dangerouslySetInnerHTML={{ __html: props.abstract }} />
        )}
        {props.cta && props.cta.url && (
          <a className="llm__card-cta" href={props.cta.url} target="_blank" rel="noreferrer">
            <span>{props.cta.label || label('llm.tellMeMore')}</span> <IconLlmChevronRightCircle />
          </a>
        )}
      </div>
    </CardWrapper>
  );
};

