import { getClassNames } from '@websolutespa/bom-core';
import { ReactNode } from 'react';

export type GridRowProps = {
  columnGap?: string;
  columns?: string;
  rowGap?: string;
  size?: string;
  children?: ReactNode;
};

// !!!
export const GridRow = (props: GridRowProps) => {
  const classNames = getClassNames('llm__row');
  return (
    <div className={classNames}>{props.children}</div>
  );
};

export type GridProps = {
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
  children?: ReactNode;
};

export const Grid = (props: GridProps) => {
  const columns = Object.fromEntries(['xs', 'sm', 'md', 'lg', 'xl'].filter(x => {
    return props[x as keyof GridProps] != null;
  }).map(x => {
    return [`--${x}-${props[x as keyof GridProps]}`, true];
  }));
  const classNames = getClassNames('llm__col', columns);
  return (
    <div className={classNames}>{props.children}</div>
  );
};

