/*
import { useCallback, useEffect, useRef } from 'react';

export function useMounted(): () => boolean {
  const ref = useRef<boolean>(false);
  const get = useCallback(() => ref.current, []);
  useEffect(() => {
    ref.current = true;
    return () => {
      ref.current = false;
    };
  }, []);
  return get;
}
*/

import { useEffect, useState } from 'react';

export function useMounted() {
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);   // at init only
  return mounted;
}
