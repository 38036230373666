
/**
 * Retrieves the default locale.
 * If the process environment variable DEFAULT_LOCALE is defined, it will be used as the default locale.
 * Otherwise, the default locale will be set to 'en'.
 * @returns The default locale.
 */
function getDefaultLocale(): string {
  let defaultLocale = 'en';
  if (typeof process !== 'undefined' && process.env.DEFAULT_LOCALE) {
    defaultLocale = process.env.DEFAULT_LOCALE;
  }
  return defaultLocale;
}

export const defaultLocale = getDefaultLocale();
