import { ILabel, ILocalizable } from '@websolutespa/bom-core';
import React from 'react';
import { createGenericContext } from '../useGenericContext/useGenericContext';
import { useLayout } from '../useLayout/useLayout';

export type ILabelContext = (key: string, params?: Record<string, any>) => string;

const [useLabel, LabelContextProvider] = createGenericContext<ILabelContext>();

function LabelProvider({ children }: { children?: React.ReactNode }) {
  const { labels } = useLayout();
  // console.log('LabelProvider', labels);
  const labelService = new LabelService(labels);
  const getLabel = (key: string, params?: Record<string, any>): string => {
    return labelService.getLabel(key, params);
  };
  return (
    <LabelContextProvider value={getLabel}>
      {children}
    </LabelContextProvider>
  );
}

class LabelService {
  labels: ILabel[];
  dictionary: Record<string, ILocalizable | undefined>;
  constructor(labels: ILabel[]) {
    this.labels = labels;
    this.dictionary = Object.fromEntries(labels.map(l => [l.id, l.text]));
  }
  getLabel(key: string, params?: Record<string, any>): string {
    return parseLabel(this.dictionary, key, params);
  }
}

function parseLabel(dictionary: Record<string, ILocalizable | undefined>, key: string, params?: Record<string, any>): string {
  const label = (dictionary[key] != null ? dictionary[key] : key) as string;
  if (params) {
    return parseParams(label, params);
  }
  return label;
}

function parseParams(label: string, params: Record<string, any>): string {
  const TEMPLATE_REGEXP: RegExp = /@(\w+)/g;
  return label.replace(TEMPLATE_REGEXP, (text: string, key: string) => {
    const replacer: unknown = params[key];
    return typeof replacer !== 'undefined' ? String(replacer) : text;
  });
}

export { LabelProvider, LabelService, parseLabel, parseParams, useLabel };

