import { getClassNames, isBrowser } from '@websolutespa/bom-core';
import { IconLlmPlay } from '@websolutespa/bom-mixer-icons';
import { useEffect, useId, useRef, useState } from 'react';
import { LlmChunkCardVideo } from '../../../types';

export const VideoPlayer = (props: LlmChunkCardVideo) => {
  const id = useId();
  const playerRef = useRef<HTMLVideoElement>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [playing, setPlaying] = useState<boolean>(false);
  useEffect(() => {
    if (isBrowser && playerRef.current) {
      const player = playerRef.current;
      const onPlay = () => {
        setPlaying(true);
      };
      const onPause = () => {
        setPlaying(false);
      };
      const onCanPlay = () => {
        setLoading(false);
      };
      player.addEventListener('canplay', onCanPlay);
      player.addEventListener('play', onPlay);
      player.addEventListener('pause', onPause);
      return () => {
        player.removeEventListener('canplay', onCanPlay);
        player.removeEventListener('play', onPlay);
        player.removeEventListener('pause', onPause);
      };
    }
    return () => { };
  }, [playerRef]);
  const onPlay = async () => {
    if (playerRef.current) {
      try {
        await playerRef.current.play();
      } catch (error: any) {
        console.log('VideoPlayer.onPlay.error', error);
      }
    }
  };
  const onToggle = async () => {
    if (playerRef.current) {
      try {
        playing ? await playerRef.current.pause() : await playerRef.current.play();
      } catch (error: any) {
        console.log('VideoPlayer.onToggle.error', error);
      }
    }
  };
  const classNames = getClassNames(
    'llm__video-player',
    'llm__video',
    { '-loading': loading, '-playing': playing }
  );
  return (
    <div className={classNames}>
      <video id={id} ref={playerRef} src={props.media.src} muted playsInline onClick={() => onToggle()} aria-label={playing ? 'Play' : 'Pause'}></video>
      <button type="button" className="llm__video-play" onClick={() => onPlay()} aria-label="Play">
        <IconLlmPlay />
      </button>
    </div>
  );
};
