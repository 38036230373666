import { LlmInstance, LlmOptions } from './types';
import { observeIntersections, observeMutations, queryElements, renderEmbed, renderInstance } from './utils';

export function bomLlm(props: LlmOptions): LlmInstance | undefined {
  if (!document) {
    return;
  }

  const body = document.querySelector('body');
  if (!body) {
    return;
  }

  const node = document.createElement('div');
  node.setAttribute('id', 'bom-llm');
  body.appendChild(node);

  const llm = {
    open: () => {
      console.log('unitialized');
    },
    dispose: () => {
      console.log('unitialized');
    },
  };

  const threadId = new URLSearchParams(window.location.search).get('llmThreadId');
  if (!props.threadId && threadId) {
    props.threadId = threadId;
  }

  renderInstance(node, props, llm);

  // observe embeds
  const onIntersection = (node: Element) => {
    // console.log('onIntersection', node);
    renderEmbed(node, props);
  };
  const targetElement = document;
  const intersectionObserver = observeIntersections(targetElement, onIntersection);
  const embeds = Array.from(document.querySelectorAll('llm-embed')).filter(element => !element.hasAttribute('data-llm'));
  embeds.forEach(element => intersectionObserver.observe(element));
  const mutationObserver = observeMutations(targetElement, ({ addedNodes, removedNodes }) => {
    // console.log('addedNodes', addedNodes);
    const addedElements = Array.from(addedNodes).filter(x => x.nodeType === Node.ELEMENT_NODE) as Element[];
    // console.log('addedElements', addedElements);
    const elements = queryElements(addedElements);
    // console.log('elements', elements);
    elements.forEach(element => intersectionObserver.observe(element));
  });
  const elements = queryElements(targetElement);
  elements.forEach(element => intersectionObserver.observe(element));

  const dispose = () => {
    intersectionObserver.disconnect();
    mutationObserver.disconnect();
  };

  llm.dispose = dispose;

  return llm;
}

if (typeof window !== 'undefined') {
  (window as unknown as {
    bomLlm: typeof bomLlm;
  }).bomLlm = bomLlm;
}

