import React from 'react';
import { Json } from '../types/various';
export * from './arrayShuffle';
export * from './asEquatable';
export * from './asSortedOptions';
export * from './babelCase';
export * from './cloneItem';
export * from './collectFeatureTypes';
export * from './collectOptions';
export * from './deepMerge';
export * from './enumToList';
export * from './typeGuards';
export * from './uuid';

export function getIsDevelopment(): boolean {
  // console.log('getIsDevelopment', process?.env?.NODE_ENV);
  return process && (!process.env.NODE_ENV || process.env.NODE_ENV === 'development');
}

export function getIsProduction(): boolean {
  // console.log('getIsProduction', process?.env?.PRODUCTION);
  return process && process.env && String(process.env.PRODUCTION) === 'true';
}

export const isDevelopment = getIsDevelopment();

export const isProduction = getIsProduction();

export const isStage = !isProduction && !isDevelopment;

export const isBrowser = typeof window !== 'undefined';

export function getEnv(): Record<string, Json> {
  const env: Record<string, Json> = {};
  const keys = Object.keys(process.env).sort();
  keys.forEach(key => {
    env[key] = process.env[key] as Json;
  });
  return env;
  // JSON.parse(JSON.stringify(process.env));
}

export function withSchema<Component extends React.FC<any>, Schema>(component: Component, schema: Schema) {
  return Object.assign(component, schema) as Component & Schema;
}

export function asServerProps(props: any): any {
  return JSON.parse(JSON.stringify(props));
}

export async function awaitAll(array: any, callback: (item: any, index: number) => Promise<any>): Promise<any[]> {
  const promises = array.map(callback);
  return await Promise.all(promises);
}

export function merge(target: any, source: any): any {
  // override null values
  if (source === null) {
    return source;
  }
  // assign new values
  if (!target) {
    if (source && typeof source === 'object') {
      return Object.assign({}, source);
    } else {
      return source;
    }
  }
  // merge objects
  if (source && typeof source === 'object') {
    Object.keys(source).forEach(key => {
      const value = source[key];
      if (typeof value === 'object' && !Array.isArray(value)) {
        target[key] = merge(target[key], value);
      } else {
        target[key] = value;
      }
    });
  }
  return target;
}
