import { KeyCode, useKeyboard, usePortal } from '@websolutespa/bom-mixer-hooks';
import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

export type DialogProps = {
  visible?: boolean;
  onClose?: () => void;
};

export const Dialog = ({
  children,
  visible: customVisible,
  ...props
}: React.PropsWithChildren<DialogProps>) => {
  const portal = usePortal('llm-dialog');
  const [visible, setVisible] = useState<boolean>(false);

  useEffect(() => {
    if (typeof customVisible === 'undefined') {
      return;
    }
    setVisible(customVisible);
  }, [customVisible]);

  const closeDrawer = () => {
    if (props.onClose) {
      props.onClose();
    }
    setVisible(false);
  };

  const { bindings } = useKeyboard(() => {
    closeDrawer();
  }, KeyCode.Escape, { disableGlobalEvent: true });

  const onBackdropClick = () => {
    closeDrawer();
  };

  if (!portal) {
    return null;
  }
  return createPortal(
    visible ? (
      <div className="llm__dialog" {...bindings}>
        <div className="llm__dialog__backdrop" onClick={onBackdropClick} />
        <div className="llm__dialog__wrapper">
          {children}
        </div>
      </div>
    ) : <></>,
    portal
  );
};
