import { getClassNames, isBrowser } from '@websolutespa/bom-core';
import { useLabel } from '@websolutespa/bom-mixer-hooks';
import { IconLlmPin } from '@websolutespa/bom-mixer-icons';
import { LlmChunkEvent, LlmChunkEventItem } from '../../../types';
import { useLlm } from '../../../useLlm/useLlm';
import { useLlmView } from '../../../useLlm/useLlmView';
import { Cta } from '../Cta/cta';

export const Event = (props: LlmChunkEventItem | LlmChunkEvent) => {
  const label = useLabel();
  const { decorateUrl } = useLlm(state => state.actions);
  const { send } = useLlmView(state => state.actions);
  const onTellMeMore = async (event: React.MouseEvent) => {
    const prompt = `${label('llm.tellMeMoreAbout')} ${props.title}`;
    await send(prompt, (response) => {
      // console.log('Prompt.onChunk', response.chunks);
    }, (response) => {
      // console.log('Prompt.onEnd', response);
    });
  };
  const onGoToWebsite = async (event: React.MouseEvent) => {
    if (isBrowser) {
      event.preventDefault();
      const url = await decorateUrl(props, props.url);
      window.open(url, '_blank');
    }
  };
  const classNames = getClassNames('llm__event', { [`llm__event--${props.type}`]: !!props.type });
  return (
    <div className={classNames}>
      <div className="llm__event-inner">
        <div className="llm__event-content">
          {props.type == 'event' && <div className="llm__event-pretitle">
            {label('llm.eventPretitle')}
          </div>}
          {props.title && (
            <div className="llm__event-title" dangerouslySetInnerHTML={{ __html: props.title }} />
          )}
          {props.abstract && (
            <div className="llm__event-body" dangerouslySetInnerHTML={{ __html: props.abstract }} />
          )}
          {props.place && (
            <div className="llm__event-location">
              <IconLlmPin /> {props.place}
            </div>
          )}
          <div className="llm__event-ctas">
            <Cta type="button" label={label('llm.tellMeMore')} onClick={onTellMeMore} />
            <Cta type="text" label={label('llm.goToWebsite')} onClick={onGoToWebsite} />
          </div>
        </div>
        {props.image && (
          <div className="llm__event-media">
            <div className="llm__event-asset">
              <img src={props.image} alt="" />
            </div>
            {(props.time || props.date) && (
              <div className="llm__event-tags">
                {props.date && (
                  <div className="llm__event-tag llm__event-date">{props.date}</div>
                )}
                {props.time && (
                  <div className="llm__event-tag llm__event-time">{props.time}</div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

