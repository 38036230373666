import { deepMerge } from '@websolutespa/bom-core';
import { LlmApp, LlmChunk, LlmContents, LlmMessage, LlmMockApp, LlmMockThread, LlmMockThreadMessage, LlmMode, LlmTest } from '../types';
import { mockApp } from './mock-app.default';
import { mockThread } from './mock-thread.default';

const MOCK_APP: LlmMockApp = mockApp;
const MOCK_THREAD: LlmMockThread = mockThread;

type MockApp = {
  mode: LlmMode;
  contents: LlmContents;
};

export function getMock(locale: string, test?: LlmTest) {
  let app: MockApp = (locale in MOCK_APP ? MOCK_APP[locale as keyof typeof MOCK_APP] : MOCK_APP) as MockApp;
  let thread: LlmMockThreadMessage[] = (locale in MOCK_THREAD ? MOCK_THREAD[locale as keyof typeof MOCK_THREAD] : MOCK_THREAD) as LlmMockThreadMessage[];
  if (typeof test === 'object') {
    if (test.app) {
      const testApp: MockApp = (locale in test.app ? test.app[locale as keyof typeof test.app] : test.app) as MockApp;
      app = deepMerge(app, testApp) as MockApp;
    }
    if (test.thread) {
      const testThread: LlmMockThreadMessage[] = (locale in test.thread ? test.thread[locale as keyof typeof test.thread] : test.thread) as LlmMockThreadMessage[];
      thread = testThread;
    }
  }
  return {
    app,
    thread,
  };
}

export function getMockApp(locale: string, threadId?: string, test?: LlmTest): LlmApp {
  const mock = getMock(locale, test);
  const app = mock.app as LlmApp;
  if (threadId) {
    const thread = mock.thread.slice(0, 4);
    app.thread = {
      messages: thread.map(x => ({
        role: x.role,
        content: x.chunks.map(x => typeof x === 'string' ? x : JSON.stringify(x)).join('\r\n'),
      })),
      threadId,
    };
  }
  return app;
}

export function getMockChunks(index: number, locale: string, test?: LlmTest): LlmChunk[] {
  const mock = getMock(locale, test);
  const chunks: LlmChunk[] = [];
  const message = (mock.thread[index] || {
    chunks: ['I\'m a teapot.\n'],
    role: 'assistant',
  }) as LlmMessage & {
    chunks: LlmChunk[];
  };
  if (message && message.role === 'assistant') {
    message.chunks.forEach(chunk => {
      if (typeof chunk === 'string') {
        chunks.push(...chunk.split(' ').map((x, i, a) => `${x}${i < a.length - 1 ? ' ' : ''}`));
      } else if (chunk.type === 'string') {
        const content = chunk.content;
        if (content) {
          chunks.push(...content.split(' ').map((x, i, a) => `${x}${i < a.length - 1 ? ' ' : ''}`));
        }
      } else {
        chunks.push(chunk);
      }
    });
  }
  return chunks;
}

export function getMockPrompt(index: number, locale: string, test?: LlmTest): string | undefined {
  const mock = getMock(locale, test);
  const message = mock.thread[index];
  if (message && message.role === 'user') {
    return message.chunks[0] as string;
  }
  return undefined;
}
