import { useLabel } from '@websolutespa/bom-mixer-hooks';
import { useLlm } from '../../../useLlm/useLlm';
import { Cta } from '../Cta/cta';
import { Text } from '../Text/text';

export const FormRequest = ({ last }: { last: boolean }) => {
  const label = useLabel();
  const { formRequest } = useLlm(state => state.actions);
  return (
    <div className="llm__row">
      <div className="llm__col --md-8 --lg-6">
        <Text body={label('llm.formRequestTitle')} />
        {last && (
          <div className="llm__ctas">
            <Cta type="simple" label={label('llm.formRequestYes')} onClick={() => formRequest(true)} />
            <Cta type="simple" label={label('llm.formRequestNo')} onClick={() => formRequest(false)} />
          </div>
        )}
      </div>
    </div>
  );
};
