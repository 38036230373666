import { useLabel } from '@websolutespa/bom-mixer-hooks';
import { FormEvent } from 'react';
import { LlmApp } from '../../../types';
import { PostSummaryOptions } from '../../../useLlm/api.service';
import { useLlm } from '../../../useLlm/useLlm';
import { Cta } from '../Cta/cta';

export type FormRecapData = {
  fullName?: string;
  email?: string;
  privacy?: string;
  threadId?: string;
};

export const FormRecap = () => {
  const label = useLabel();
  const app = useLlm(state => state.app);
  const threadId = useLlm(state => state.threadId);
  const { formRecap, getApi } = useLlm(state => state.actions);

  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      const formData = new FormData(event.target as HTMLFormElement);
      const data: FormRecapData = {};
      for (const [key, value] of formData) {
        data[key as keyof typeof data] = value as string;
      }
      data.threadId = threadId;
      const payload: PostSummaryOptions = {
        ...(data as Required<FormRecapData>),
        html: getEmailHtml(data, app),
      };
      const api = getApi();
      const response = await api.postSummary(payload);
      if (response) {
        console.warn('FormRecap.onSubmit.success', response, payload);
        formRecap();
      } else {
        console.warn('FormRecap.onSubmit.error');
        formRecap({ message: 'unknown error' });
      }
    } catch (error) {
      console.warn('FormRecap.onSubmit.error', error);
      formRecap(error);
    }
  };

  return (
    <div className="llm__subscribe">
      <div className="llm__subscribe-wrapper">
        <form className="llm__subscribe-form" onSubmit={onSubmit}>
          <div className="llm__subscribe-title">{label('llm.formRecapTitle')}</div>
          <div className="llm__subscribe-fields">
            <div className="llm__subscribe-field">
              <input type="text" name="fullName" id="subscribe-fullName" placeholder={label('llm.formRecapFullName')} />
            </div>
            <div className="llm__subscribe-field">
              <input type="email" name="email" id="subscribe-email" placeholder={label('llm.formRecapEmail')} />
            </div>
            <div className="llm__subscribe-field -privacy">
              <input type="checkbox" name="privacy" id="subscribe-privacy" />
              <label htmlFor="subscribe-privacy">{label('llm.formRecapPrivacy')}</label>
            </div>
            <div className="llm__subscribe-cta">
              <Cta type="submit" label={label('llm.formRecapSend')} />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};


export function getEmailHtml(data: FormRecapData, app?: LlmApp): string {
  const firstName = data.fullName ? data.fullName.split(' ')[0] : '';
  const name = app?.contents?.shortWelcomeText || 'PlatformAI';
  const title = `Ciao ${firstName}`;
  const paragraphs = `<p>${[
    'Ecco il tuo promemoria,',
    'per qualunque altra cosa sai dove potermi trovare, sarò sempre lieto di parlare con te. 👋',
  ].join('</p><p>')}</p>`;
  const colorAccent = '#00aeff';
  const threadUrl = window.location.href + `${window.location.search ? '&' : '?'}llmThreadId=${data.threadId}`;
  return `
<!doctype html>
<html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">

<head>
  <title> ${title} </title>
  <!--[if !mso]><!-->
  <meta http-equiv="X-UA-Compatible" content="IE=edge">
  <!--<![endif]-->
  <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1">
  <style type="text/css">
    #outlook a {
      padding: 0;
    }

    body {
      margin: 0;
      padding: 0;
      -webkit-text-size-adjust: 100%;
      -ms-text-size-adjust: 100%;
    }

    table,
    td {
      border-collapse: collapse;
      mso-table-lspace: 0pt;
      mso-table-rspace: 0pt;
    }

    img {
      border: 0;
      height: auto;
      line-height: 100%;
      outline: none;
      text-decoration: none;
      -ms-interpolation-mode: bicubic;
    }

    p {
      display: block;
      margin: 13px 0;
    }
  </style>
  <!--[if mso]>
        <noscript>
        <xml>
        <o:OfficeDocumentSettings>
          <o:AllowPNG/>
          <o:PixelsPerInch>96</o:PixelsPerInch>
        </o:OfficeDocumentSettings>
        </xml>
        </noscript>
        <![endif]-->
  <!--[if lte mso 11]>
        <style type="text/css">
          .mj-outlook-group-fix { width:100% !important; }
        </style>
        <![endif]-->
  <!--[if !mso]><!-->
  <link href="https://fonts.googleapis.com/css?family=Inter:400,600" rel="stylesheet" type="text/css">
  <style type="text/css">
    @import url(https://fonts.googleapis.com/css?family=Inter:400,600);
  </style>
  <!--<![endif]-->
  <style type="text/css">
    @media only screen and (min-width:480px) {
      .mj-column-per-100 {
        width: 100% !important;
        max-width: 100%;
      }
    }
  </style>
  <style media="screen and (min-width:480px)">
    .moz-text-html .mj-column-per-100 {
      width: 100% !important;
      max-width: 100%;
    }
  </style>
  <style type="text/css">
  </style>
</head>

<body style="word-spacing:normal;">
  <div style="">
    <!--[if mso | IE]><table align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
    <div style="margin:0px auto;max-width:600px;">
      <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width:100%;">
        <tbody>
          <tr>
            <td style="direction:ltr;font-size:0px;padding:0px;padding-top:20px;text-align:center;">
              <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:600px;" ><![endif]-->
              <div class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                  <tbody>
                    <tr>
                      <td align="left" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                        <div style="font-family:Inter;font-size:20px;font-weight:600;line-height:24px;text-align:left;color:#3b3b3b;">${name}</div>
                      </td>
                    </tr>
                    <tr>
                      <td align="center" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                        <p style="border-top:solid 3px #EFEFF2;font-size:1px;margin:0px auto;width:100%;">
                        </p>
                        <!--[if mso | IE]><table align="center" border="0" cellpadding="0" cellspacing="0" style="border-top:solid 3px #EFEFF2;font-size:1px;margin:0px auto;width:550px;" role="presentation" width="550px" ><tr><td style="height:0;line-height:0;"> &nbsp;
</td></tr></table><![endif]-->
                      </td>
                    </tr>
                    <tr>
                      <td align="left" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                        <div style="font-family:Inter;font-size:28px;font-weight:600;line-height:24px;text-align:left;color:#3b3b3b;">${title}</div>
                      </td>
                    </tr>
                    <tr>
                      <td align="left" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                        <div style="font-family:Inter, Helvetica, sans-serif;font-size:16px;font-weight:400;line-height:24px;text-align:left;color:#555555;">${paragraphs} <h3 style="font-weight: 600; margin-top: 20px; margin-bottom: 0">
                            <a href="${threadUrl}" style="color: ${colorAccent}; text-decoration: underline">link</a>
                          </h3>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!--[if mso | IE]></td></tr></table><![endif]-->
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!--[if mso | IE]></td></tr></table><![endif]-->
  </div>
</body>

</html>
`;
}
