import { isBrowser } from '@websolutespa/bom-core';
import { LlmApp, LlmCredentials, LlmTest } from '../types';
import { getMockApp } from './mock';

export type ApiServiceOptions = LlmCredentials & {
  threadId?: string;
  endpoint: string | undefined;
  locale: string;
  test?: LlmTest;
  preview?: boolean;
};

export class ApiService {

  options: ApiServiceOptions;

  constructor(options: ApiServiceOptions) {
    this.options = options;
  }

  get baseUrl() {
    const origin = this.options.endpoint || (isBrowser ? window.location.origin : process.env.STORE_URL);
    const baseUrl = `${origin}/api/llm`;
    return baseUrl;
  }

  async get<T = unknown>(pathname: string) {
    const url = `${this.baseUrl}${pathname}${pathname.indexOf('?') !== -1 ? '&' : '?'}locale=${this.options.locale}`;
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (response.ok) {
        const data = await response.json() as T;
        return data;
      } else {
        console.error('useLlm.api.get', url, response);
        return undefined;
      }
    } catch (error) {
      console.error('useLlm.api.get', url, error);
      return undefined;
    }
  }

  async post<T = unknown>(pathname: string, payload?: {}) {
    const options = this.options;
    const url = `${this.baseUrl}${pathname}${pathname.indexOf('?') !== -1 ? '&' : '?'}locale=${options.locale}`;
    try {
      const response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify({
          ...options,
          ...payload,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (response.ok) {
        const data = await response.json() as T;
        return data;
      } else {
        console.error('useLlm.api.post', response, url);
        return undefined;
      }
    } catch (error) {
      console.error('useLlm.api.post', error, url);
      return undefined;
    }
  }

  async getInfo() {
    if (this.options.test) {
      return this.getInfoMock();
    }
    const app = await this.post<LlmApp>('/info');
    return app;
  }

  async getInfoMock() {
    const { locale, appKey, apiKey, threadId } = this.options;
    if (typeof locale !== 'string') {
      throw { status: 400, message: 'Bad Request: locale is missing' };
    }
    if (!appKey) {
      throw { status: 401, message: 'Unauthorized: appKey is missing' };
    }
    if (!apiKey) {
      throw { status: 401, message: 'Unauthorized: apiKey is missing' };
    }
    const app = getMockApp(locale, threadId, this.options.test);
    return app;
  }

  async postSummary(payload: PostSummaryOptions) {
    if (this.options.test) {
      return this.postSummaryMock(payload);
    }
    const response = await this.post('/summary', payload);
    return response;
  }

  async postSummaryMock(payload: PostSummaryOptions) {
    const { locale, appKey, apiKey, threadId } = this.options;
    if (typeof locale !== 'string') {
      throw { status: 400, message: 'Bad Request: locale is missing' };
    }
    if (!appKey) {
      throw { status: 401, message: 'Unauthorized: appKey is missing' };
    }
    if (!apiKey) {
      throw { status: 401, message: 'Unauthorized: apiKey is missing' };
    }
    if (!threadId) {
      throw { status: 400, message: 'Bad Request: threadId is missing' };
    }
    const { email, html } = payload;
    if (!email) {
      throw { status: 400, message: 'Bad Request: email is missing' };
    }
    if (!html) {
      throw { status: 400, message: 'Bad Request: html is missing' };
    }
    // console.log('/llm/summary', payload);
    return {
      code: 'SENT',
    };
  }

}

export type FormRecapData = {
  fullName: string;
  email: string;
  privacy: string;
  threadId: string;
};

export type PostSummaryOptions = FormRecapData & {
  html: string;
};
