import { getClassNames } from '@websolutespa/bom-core';
import { useWindowSize } from '@websolutespa/bom-mixer-hooks';
import { IconLlmArrowLeft, IconLlmArrowRight } from '@websolutespa/bom-mixer-icons';
import { useRef } from 'react';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { LlmChunkCardGroup } from '../../../types';
import { clamp } from '../../utils';
import { Card } from '../Card/card';

export const CardGroup = (props: LlmChunkCardGroup) => {

  useWindowSize();

  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);
  const swiperProps = {
    modules: [Navigation],
    spaceBetween: clamp(16, 24),
    slidesPerView: 'auto' as const,
    slidesPerGroup: 1,
    watchOverflow: true,
    centeredSlides: false,
    navigation: {
      prevEl: navigationPrevRef.current,
      nextEl: navigationNextRef.current,
    },
    breakpoints: {
      630: {
        // slidesPerView: 1.5,
        slidesPerGroup: 1,
      },
      768: {
        // slidesPerView: 1.5,
        slidesPerGroup: 1,
      },
      1024: {
        // slidesPerView: 2,
        slidesPerGroup: 2,
      },
      1280: {
        // slidesPerView: 3,
        slidesPerGroup: 3,
      },
    },
  };

  const classNames = getClassNames('llm__cards');
  return (
    <div className={classNames}>
      <Swiper {...swiperProps}>
        <div className="llm__cards-nav">
          <button ref={navigationPrevRef}><IconLlmArrowLeft /></button>
          <button ref={navigationNextRef}><IconLlmArrowRight /></button>
        </div>
        {props.items.map((item, i) => (
          <SwiperSlide key={i} className="llm__cards-slide">
            <Card {...item} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

