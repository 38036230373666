import { isObject } from '@websolutespa/bom-core';
import { v4 as uuid } from 'uuid';
import { LlmChunk, LlmChunkError, LlmChunkItem, LlmCredentials, LlmDecodedMessage, LlmMessage, LlmTest, StreamRequest, StreamResponse } from '../types';
import { getMockChunks, getMockPrompt } from './mock';

export type MessageServiceRequest = StreamRequest;

export type MessageServiceResponse = {
};

export type MessageServiceOptions = LlmCredentials & {
  endpoint: string | undefined;
  locale: string;
  test?: LlmTest;
};

export class MessageDecoder {

  private decoder_ = new TextDecoder('utf-8');

  private lastUnparsedText_: string = '';
  set lastUnparsedText(lastUnparsedText: string) {
    // console.log('MessageService.lastUnparsedText.set', lastUnparsedText);
    this.lastUnparsedText_ = lastUnparsedText;
  }
  get lastUnparsedText(): string {
    return this.lastUnparsedText_;
  }

  constructor() {
  }

  bytesToChunks(byteArray: Uint8Array): LlmChunk[] {
    let chunks: LlmChunk[] = [];
    let text: string = '';
    try {
      text = this.decoder_.decode(byteArray);
      chunks = this.textToChunks(text);
    } catch (error) {
      console.log('decodedChunk.error', error, text);
    }
    // console.log(chunks, text);
    return chunks;
  }

  textToChunks(text: string): LlmChunk[] {
    let chunks: LlmChunk[] = [];
    let jsonText: string = '';
    try {
      if (text.match(/(",|},|],)$/)) {
        let textToReplace = this.lastUnparsedText + text;
        if (textToReplace.match(/^(,)/)) {
          console.log('textToChunks.error', `incorrect line starting [${text}]`);
          textToReplace = textToReplace.substring(1);
        }
        const replacedText = textToReplace.replace(/(,$)/, '');
        jsonText = `[${replacedText}]`;
        const jsonChunks: LlmChunk[] = JSON.parse(jsonText);
        chunks = jsonChunks;
        this.lastUnparsedText = '';
      } else {
        console.log('textToChunks.error', `incorrect line ending [${text}]`);
        this.lastUnparsedText += text;
      }
    } catch (error) {
      console.log('textToChunks.error', error);
      this.lastUnparsedText += text;
    }
    return chunks;
  }

  decodeMessages(messages: LlmMessage[]): LlmDecodedMessage[] {
    return messages.map(x => {
      if (x.role === 'user') {
        return ({
          ...x,
          chunks: [{
            type: 'string',
            content: x.content,
          }],
        });
      }
      this.lastUnparsedText = '';
      return ({
        ...x,
        chunks: this.chunksToChunkItems(this.textToChunks(x.content)),
      });
    });
  }

  chunksToResponse(chunks: LlmChunk[]): StreamResponse {
    const response: StreamResponse = {
      chunks: [],
      threadId: '',
    };
    response.chunks = this.chunksToChunkItems(chunks, response);
    return response;
  }

  logChunks(chunks: LlmChunk[]) {
    chunks.forEach(x => {
      if (isObject(x)) {
        switch (x.type) {
          case 'error':
            console.error('LlmMessageService.error', x);
            break;
          case 'log':
            console.log('LlmMessageService.log', x);
            break;
          case 'info':
            console.log('LlmMessageService.info', x);
            break;
          case 'end':
            console.log('LlmMessageService.end', x);
            break;
          default:
        }
      }
    });
  }

  chunksToChunkItems(chunks: LlmChunk[], response?: StreamResponse): LlmChunkItem[] {
    const chunkItems: LlmChunkItem[] = [];
    chunks.forEach(x => {
      const lastMessage = chunkItems[chunkItems.length - 1];
      if (isObject(x)) {
        switch (x.type) {
          case 'log':
            break;
          case 'error':
            chunkItems.push(x);
            break;
          case 'info':
          case 'end': {
            const { type, ...rest } = x;
            if (response) {
              Object.assign(response, rest);
            }
            // response = { ...response, ...rest };
          }
            break;
          case 'cardItem':
            if (lastMessage && lastMessage.type === 'cardGroup') {
              lastMessage.items.push(x);
            } else {
              chunkItems.push({
                type: 'cardGroup',
                items: [x],
              });
            }
            break;
          case 'productItem':
            if (lastMessage && lastMessage.type === 'productGroup') {
              lastMessage.items.push(x);
            } else {
              chunkItems.push({
                type: 'productGroup',
                items: [x],
              });
            }
            break;
          case 'eventItem':
            if (lastMessage && lastMessage.type === 'eventGroup') {
              lastMessage.items.push(x);
            } else {
              chunkItems.push({
                type: 'eventGroup',
                items: [x],
              });
            }
            break;
          case 'poiItem':
            if (lastMessage && lastMessage.type === 'poiGroup') {
              lastMessage.items.push(x);
            } else {
              chunkItems.push({
                type: 'poiGroup',
                items: [x],
              });
            }
            break;
          case 'tripadvisor':
          case 'tripadvisorItem':
            if (lastMessage && lastMessage.type === 'tripadvisorGroup') {
              lastMessage.items.push({ ...x, type: 'tripadvisorItem' });
            } else {
              chunkItems.push({
                type: 'tripadvisorGroup',
                items: [{ ...x, type: 'tripadvisorItem' }],
              });
            }
            break;
          default:
            chunkItems.push(x);
        }
      } else if (typeof x === 'string') {
        // console.warn('chunksToChunkItems typeof string');
        if (lastMessage && lastMessage.type === 'string') {
          if (chunkItems.indexOf(lastMessage) > 0) {
            // secondary chunk
            lastMessage.content += x;
          } else if (!lastMessage.content.includes('\n')) {
            // uncompleted first chunk
            if (x.includes('\n')) {
              const contents = x.split('\n');
              contents.forEach((content, i) => {
                const lastChar = (contents.length > i + 1) ? '\n' : '';
                if (i === 0) {
                  lastMessage.content += content + lastChar;
                } else if (content.length > 0) {
                  chunkItems.push({
                    type: 'string',
                    content: content + lastChar,
                  });
                }
              });
            } else {
              // uncompleted first chunk
              lastMessage.content += x;
            }
          } else {
            // completed first chunk
            chunkItems.push({
              type: 'string',
              content: x,
            });
          }
        } else {
          // new text chunk
          chunkItems.push({
            type: 'string',
            content: x,
          });
        }
        /*
        if (lastMessage && lastMessage.type === 'string' && !lastMessage.content.includes('\n')) {
          if (x.includes('\n')) {
            const contents = x.split('\n');
            contents.forEach((content, i) => {
              const lastChar = (contents.length > i + 1) ? '\n' : '';
              if (i === 0 || chunkItems.indexOf(lastMessage) > 0) {
                lastMessage.content += content + lastChar;
              } else if (content.length > 0) {
                chunkItems.push({
                  type: 'string',
                  content: content + lastChar,
                });
              }
            });
          } else {
            lastMessage.content += x;
          }
        } else {
          chunkItems.push({
            type: 'string',
            content: x,
          });
        }
        */
      }
    });
    return chunkItems;
  }

}

export class MessageService extends MessageDecoder {

  options: MessageServiceOptions;

  constructor(options: MessageServiceOptions) {
    super();
    this.options = options;
  }

  get origin(): string {
    return this.options.endpoint || window.location.origin;
  }

  get locale(): string {
    return this.options.locale || '';
  }

  get url(): string {
    return `${this.origin}/api/llm/message?locale=${this.locale}`;
  }

  get appKey(): string {
    return this.options.appKey || '';
  }

  get apiKey(): string {
    return this.options.apiKey || '';
  }

  get test(): LlmTest {
    return this.options.test || false;
  }

  private lastMessage_: {
    aborter: AbortController;
    onEnd?: (response: StreamResponse) => void;
    decodedChunks: LlmChunk[];
    reader?: ReadableStreamDefaultReader<Uint8Array>;
  } | undefined;

  async addUserMessage(messages: LlmDecodedMessage[], prompt: string): Promise<LlmDecodedMessage[]> {
    if (this.test) {
      prompt = getMockPrompt(messages.length, this.locale, this.test) || prompt;
    }
    const userMessage = {
      chunks: [{
        type: 'string' as const,
        content: prompt,
      }],
      content: prompt,
      role: 'user',
    };
    return [...messages, userMessage];
  }

  async getRequest(messages: LlmDecodedMessage[], threadId: string | undefined): Promise<StreamRequest> {
    return {
      appKey: this.appKey,
      apiKey: this.apiKey,
      messages: messages.filter(x => {
        const chunks = x.chunks
          .filter(x => !['info', 'end', 'formRequest', 'formRecap', 'formRecapSuccess', 'formRecapError'].includes(x.type));
        return chunks.length > 0;
      }).map(x => ({ role: x.role, content: x.content })),
      threadId,
    };
  }

  async sendMessage(
    request: MessageServiceRequest,
    onMessage?: (response: StreamResponse) => void,
    onEnd?: (response: StreamResponse) => void,
    onChunk?: (chunks: LlmChunk[]) => void
  ) {
    if (this.test) {
      return this.mockMessage(request, onMessage, onEnd, onChunk);
    }
    this.abort();
    // console.log('MessageServiceService.request', request);
    const aborter = new AbortController();
    const decodedChunks: LlmChunk[] = [];
    this.lastMessage_ = {
      aborter,
      decodedChunks,
      onEnd,
    };
    const signal = aborter.signal;
    const url = this.url;
    let bytes = 0;
    try {
      const response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(request),
        headers: {
          'Content-Type': 'application/json',
        },
        signal,
      });
      if (response.body) {
        const reader = response.body.getReader();
        this.lastMessage_.reader = reader;
        this.lastUnparsedText = '';
        // console.log('lastUnparsedText.clear');
        for await (const chunk of readChunks(reader)) {
          if (!signal.aborted) {
            bytes += chunk.length;
            const chunks = this.bytesToChunks(chunk);
            this.logChunks(chunks);
            decodedChunks.push(...chunks);
            const response = this.chunksToResponse(decodedChunks);
            if (typeof onMessage === 'function') {
              onMessage(response);
            }
            if (typeof onChunk === 'function') {
              onChunk(chunks);
            }
          }
        }
        if (!signal.aborted) {
          if (typeof onEnd === 'function') {
            const response = this.chunksToResponse(decodedChunks);
            onEnd(response);
          }
        }
      }
    } catch (error) {
      console.error('MessageService.sendMessage.error', error);
      let errorMessage = typeof error === 'string' ? error : JSON.stringify(error, null, 2);
      if (error instanceof TypeError) {
        errorMessage = 'TypeError: Browser may not support async iteration';
      }
      if (typeof onEnd === 'function') {
        const errorChunk: LlmChunkError = {
          type: 'error',
          error: errorMessage,
        };
        decodedChunks.push(errorChunk);
        const response = this.chunksToResponse(decodedChunks);
        onEnd(response);
      }
      // throw (error);
    }
  }

  async mockMessage(
    request: MessageServiceRequest,
    onMessage?: (response: StreamResponse) => void,
    onEnd?: (response: StreamResponse) => void,
    onChunk?: (chunks: LlmChunk[]) => void
  ) {
    // console.log('mockMessage', request);
    this.abort();
    const aborter = new AbortController();
    const { messages } = request;
    if (!messages || messages.length === 0) {
      throw { status: 400, message: 'Bad Request: messages is missing' };
    }
    let threadId = request.threadId;
    // threadId is required when messages.length > 1
    if (!threadId && messages.length > 1) {
      throw { status: 400, message: 'Bad Request: threadId is missing' };
    }
    if (!threadId) {
      threadId = uuid();
    }
    const decodedChunks: LlmChunk[] = [];
    this.lastMessage_ = {
      aborter,
      decodedChunks,
      onEnd,
    };
    let bytes = 0;
    const signal = aborter.signal;
    const chunks = getMockChunks(request.messages.length, this.locale, this.test);
    chunks.unshift({ type: 'info', threadId });
    chunks.push({ type: 'end' });
    try {
      let controller_: ReadableStreamDefaultController<unknown>;
      const send = async function () {
        const encoder = new TextEncoder();
        await new Promise(x => setTimeout(x, 1000));
        for (const chunk of chunks) {
          if (!signal.aborted) {
            const array = encoder.encode(`${JSON.stringify(chunk)},`);
            controller_.enqueue(array);
            await new Promise(x => setTimeout(x, typeof chunk === 'string' ? 40 : 500));
          }
        }
        if (!signal.aborted) {
          controller_.close();
        }
      };
      const readableStream = new ReadableStream({
        start(controller) {
          controller_ = controller;
          send();
        },
        pull(controller) {
          // We don't really need a pull in this example
        },
        cancel() {
          aborter.abort;
        },
      });
      const reader = readableStream.getReader();
      this.lastMessage_.reader = reader;
      this.lastUnparsedText = '';
      // console.log('lastUnparsedText.clear');
      for await (const chunk of readChunks(reader)) {
        if (!signal.aborted) {
          bytes += chunk.length;
          const chunks = this.bytesToChunks(chunk);
          this.logChunks(chunks);
          decodedChunks.push(...chunks);
          const response = this.chunksToResponse(decodedChunks);
          if (typeof onMessage === 'function') {
            onMessage(response);
          }
          if (typeof onChunk === 'function') {
            onChunk(chunks);
          }
        }
      }
      if (!signal.aborted) {
        if (typeof onEnd === 'function') {
          const response = this.chunksToResponse(decodedChunks);
          onEnd(response);
        }
      }
    } catch (error) {
      console.error('MessageService.mockMessage.error', error);
      let errorMessage = typeof error === 'string' ? error : JSON.stringify(error, null, 2);
      if (error instanceof TypeError) {
        errorMessage = 'TypeError: Browser may not support async iteration';
      }
      if (typeof onEnd === 'function') {
        const errorChunk: LlmChunkError = {
          type: 'error',
          error: errorMessage,
        };
        decodedChunks.push(errorChunk);
        const response = this.chunksToResponse(decodedChunks);
        onEnd(response);
      }
      // throw (error);
    }
  }

  abort() {
    const lastMessage = this.lastMessage_;
    if (lastMessage && lastMessage.aborter && !lastMessage.aborter.signal.aborted) {
      try {
        if (lastMessage.reader) {
          lastMessage.reader.cancel().catch((error) => {
            console.log('MessageService.abort.reader.error', error);
          });
        }
        lastMessage.aborter.abort();
      } catch (error) {
        console.log('MessageService.abort.error', error);
      }
      if (typeof lastMessage.onEnd === 'function') {
        const response = this.chunksToResponse(lastMessage.decodedChunks);
        lastMessage.onEnd(response);
      }
    }
  }

  shouldAddFormRequest(messages: LlmDecodedMessage[]): boolean {
    const isTestThread = this.test && getMockPrompt(messages.length, this.locale, this.test) !== undefined;
    const hasFormRequest = messages.find(x => x.chunks.find(x => x.type === 'formRequest') !== undefined);
    const importantMessages = messages.filter(x => x.chunks.find(x => ['event', 'eventItem', 'eventGroup', 'poi', 'poiItem', 'poiGroup', 'tripadvisor', 'tripadvisorItem', 'tripadvisorGroup'].includes(x.type)) !== undefined);
    return !isTestThread && !hasFormRequest && importantMessages.length > 1;
  }

}

function readChunks<T>(reader: ReadableStreamDefaultReader<T>) {
  return {
    async*[Symbol.asyncIterator]() {
      let readResult = await reader.read();
      while (!readResult.done) {
        yield readResult.value;
        readResult = await reader.read();
      }
    },
  };
}

/*
async function* streamAsyncIterable<T>(stream: ReadableStream<T>) {
  const reader = stream.getReader();
  try {
    while (true) {
      const { done, value } = await reader.read();
      if (done) return;
      yield value;
    }
  } finally {
    reader.releaseLock();
  }
}

class Iterator {
}

(Iterator as any).prototype[Symbol.asyncIterator] = async function* () {
  const reader = this.getReader();
  try {
    while (true) {
      const { done, value } = await reader.read();
      if (done) return;
      yield value;
    }
  }
  finally {
    reader.releaseLock();
  }
};
*/
