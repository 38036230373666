import { isBrowser } from '@websolutespa/bom-core';
import { createGenericContext } from '@websolutespa/bom-mixer-hooks';
import ArrayBuffer from 'nanogl/arraybuffer';
import Program from 'nanogl/program';
import { MutableRefObject, RefObject, useEffect, useRef } from 'react';
import { useLlm } from '../../../useLlm/useLlm';

const vertex = `attribute vec2 aPosition;
attribute vec2 aTexCoord;
varying vec2 vUv;

void main(void){
    gl_Position = vec4(aPosition, 0.0, 1.0);
    vUv = aTexCoord;
}
`;

const fragment = `
#ifdef GL_ES
precision mediump float;
#endif

varying vec2 vUv;

uniform int uColor1;
uniform int uColor2;
uniform int uColor3;
uniform int uColor4;
uniform float uBlobSize;
uniform float uCellSize;
uniform float uNoiseSize;
uniform float uOpacity;
uniform float uScale;
uniform float uTime;

vec2 random2(vec2 st) {
  st = vec2(dot(st, vec2(127.1, 311.7)), dot(st, vec2(269.5, 183.3)));
  return - 1.0 + 2.0 * fract(sin(st) * 43758.5453123);
}

float noise(vec2 st) {
  vec2 i = floor(st);
  vec2 f = fract(st);
  vec2 u = f * f * (3.0 - 2.0 * f);
  return mix(mix(dot(random2(i + vec2(0.0, 0.0)), f - vec2(0.0, 0.0)),
  dot(random2(i + vec2(1.0, 0.0)), f - vec2(1.0, 0.0)), u.x),
  mix(dot(random2(i + vec2(0.0, 1.0)), f - vec2(0.0, 1.0)),
  dot(random2(i + vec2(1.0, 1.0)), f - vec2(1.0, 1.0)), u.x), u.y) * 0.5 + 0.5;
}

float gaussFunction(vec2 st, vec2 p, float r) {
  return exp(-dot(st - p, st - p) / 2.0 / r / r);
}

vec3 hash32(vec2 p) {
  vec3 p3 = fract(vec3(p.xyx) * vec3(0.1031, 0.1030, 0.0973));
  p3 += dot(p3, p3.yxz + 33.33);
  return fract((p3.xxy + p3.yzz) * p3.zyx);
}

vec3 blob(vec2 p) {
  vec2 i = floor(p / uCellSize);
  vec3 c = vec3(0.0);
  for(int x = -1; x <= 1; x ++ )
  for(int y = -1; y <= 1; y ++ ) {
      vec2 v = i + vec2(x, y);
      vec3 h = hash32(v);
      float g = gaussFunction(p / uCellSize, v + h.xy, uBlobSize);
      float n = smoothstep(0.0, 1.0, noise(uNoiseSize * p / uCellSize / uBlobSize));
      c += uOpacity * g * n;
  }
  return c;
}

float random(vec2 st) {
  return fract(sin(dot(st.xy, vec2(12.9898, 78.233))) * 43758.5453123);
}

vec3 intToColor(int color) {
  float r = float(color / 256 / 256);
  float g = float(color / 256 - int(r * 256.0));
  float b = float(color - int(r * 256.0 * 256.0) - int(g * 256.0));
  return vec3(r / 255.0, g / 255.0, b / 255.0);
}

void main(void) {
  vec2 st = vUv / uScale;

  vec2 p1 = st + vec2(cos(uTime * 0.1), sin(uTime * 0.1));
  vec3 b1 = blob(p1);

  vec2 p2 = st + 3.43 + vec2(sin(uTime * 0.2), cos(uTime * 0.2));
  vec3 b2 = blob(p2);

  vec2 p3 = st + 5.43 + vec2(cos(uTime * 0.2), cos(uTime * 0.2));
  vec3 b3 = blob(p3);

  vec3 mask = (1.0 - b1 - b2 - b3);

  vec3 c1 = intToColor(uColor1);
  vec3 c2 = intToColor(uColor2);
  vec3 c3 = intToColor(uColor3);
  vec3 c4 = intToColor(uColor4);

  vec3 color = mask * c1;

  color += b1 * c2;

  color += b2 * c3;

  color += b3 * c4;

  // noise
  vec2 n = random2(st) * 0.025;
  color += n.x - n.y;

  gl_FragColor = vec4(color, 1.0);
}
`;

const shader = {
  vertex,
  fragment,
};

export type ShaderProps = {
  color1: string;
  color2: string;
  color3: string;
  color4: string;
  blobSize?: number;
  cellSize?: number;
  noiseSize?: number;
  opacity?: number;
  scale?: number;
};

const initShader = (
  canvasOffscreen: HTMLCanvasElement,
  {
    color1,
    color2,
    color3,
    color4,
    blobSize = 0.3,
    cellSize = 0.6,
    noiseSize = 0.3,
    opacity = 0.5,
    scale = 1,
  }: ShaderProps
) => {
  const gl = canvasOffscreen.getContext('webgl', {
    preserveDrawingBuffer: true,
  });
  if (!gl) {
    throw 'cannot initialize gl';
  }

  const pixelRatio = window.devicePixelRatio;
  const size = {
    width: 1,
    height: 1,
  };

  const shouldRenderSingleFrame = isApple();
  let canRender = true;
  let isDirty = true;
  let isDirtyVars = true;
  let rafID: number | null = null;

  const onResize = () => {
    const canvasWidth = window.innerWidth;
    const canvasHeight = window.innerHeight;
    canvasOffscreen.width = Math.round(canvasWidth * pixelRatio);
    canvasOffscreen.height = Math.round(canvasHeight * pixelRatio);
    size.width = gl.drawingBufferWidth;
    size.height = gl.drawingBufferHeight;
    isDirty = true;
  };

  const quadData = new Float32Array([-1, 3, 0, 2, -1, -1, 0, 0, 3, -1, 2, 0]);
  const quad = new ArrayBuffer(gl, quadData);
  quad.attrib('aPosition', 2, gl.FLOAT);
  quad.attrib('aTexCoord', 2, gl.FLOAT);

  const program = new Program(gl, shader.vertex, shader.fragment);

  const map = new Map<HTMLCanvasElement, HTMLCanvasElement>();

  const vars = {
    uColor1: parseInt(color1.replace('#', ''), 16),
    uColor2: parseInt(color2.replace('#', ''), 16),
    uColor3: parseInt(color3.replace('#', ''), 16),
    uColor4: parseInt(color4.replace('#', ''), 16),
    uBlobSize: blobSize,
    uCellSize: cellSize,
    uNoiseSize: noiseSize,
    uOpacity: opacity,
    uScale: scale,
  };

  const render = (time = 0) => {
    if (!canRender) {
      return;
    }

    if (!isDirty) {
      rafID = window.requestAnimationFrame(render);
      return;
    }

    gl.viewport(0, 0, size.width, size.height);
    gl.clearColor(0, 0, 0, 0);
    gl.clear(gl.COLOR_BUFFER_BIT);

    program.use();

    if (isDirtyVars) {
      program.uColor1(vars.uColor1);
      program.uColor2(vars.uColor2);
      program.uColor3(vars.uColor3);
      program.uColor4(vars.uColor4);
      program.uBlobSize(vars.uBlobSize);
      program.uCellSize(vars.uCellSize);
      program.uNoiseSize(vars.uNoiseSize);
      program.uOpacity(vars.uOpacity);
      program.uScale(vars.uScale);
      isDirtyVars = false;
    }

    program.uTime(time * 0.001);

    quad.attribPointer(program);
    quad.drawTriangles();

    if (map.size > 0) {
      map.forEach((canvas: HTMLCanvasElement) => {
        const context = canvas.getContext('2d');
        if (context) {
          context.drawImage(canvasOffscreen, 0, 0, canvasOffscreen.width, canvasOffscreen.height, 0, 0, canvas.width, canvas.height);
        }
      });
    }
    isDirty = !shouldRenderSingleFrame;
    rafID = window.requestAnimationFrame(render);
  };

  const setUniforms = (uniforms: Partial<ShaderProps>) => {
    if (uniforms.color1) {
      vars.uColor1 = parseInt(uniforms.color1.replace('#', ''), 16);
    }
    if (uniforms.color2) {
      vars.uColor2 = parseInt(uniforms.color2.replace('#', ''), 16);
    }
    if (uniforms.color3) {
      vars.uColor3 = parseInt(uniforms.color3.replace('#', ''), 16);
    }
    if (uniforms.color4) {
      vars.uColor4 = parseInt(uniforms.color4.replace('#', ''), 16);
    }
    isDirtyVars = true;
  };

  setTimeout(() => {
    onResize();
    render();
  }, 0);

  window.addEventListener('resize', onResize);

  const dispose = () => {
    canRender = false;
    if (rafID) {
      window.cancelAnimationFrame(rafID);
    }
    window.removeEventListener('resize', onResize);
    program.dispose();
  };

  const attach = (canvas: HTMLCanvasElement) => {
    if (!map.has(canvas)) {
      map.set(canvas, canvas);
      isDirty = true;
    }
  };

  const detach = (canvas: HTMLCanvasElement) => {
    map.delete(canvas);
  };

  const setDirty = () => {
    isDirty = true;
  };

  return { dispose, setUniforms, attach, detach, setDirty };
};

export type Shader = {
  dispose: () => void,
  setUniforms: (uniforms: Partial<ShaderProps>) => void,
  attach: (canvas: HTMLCanvasElement) => void;
  detach: (canvas: HTMLCanvasElement) => void;
  setDirty: () => void;
};

function isApple() {
  return [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod',
  ].includes(navigator.platform) ||
    (navigator.platform.indexOf('Mac') === 0) ||
    (navigator.userAgent.includes('Mac'));
}

export type CanvasOffscreenProps = {
};

let sharedCanvas: HTMLCanvasElement | undefined;
let sharedShader: Shader | undefined;

export type CanvasOffscreenContext = {
  canvasRef: MutableRefObject<HTMLCanvasElement | undefined>;
  shaderRef: MutableRefObject<Shader | undefined>;
  attach: (canvas: HTMLCanvasElement) => void;
  detach: (canvas: HTMLCanvasElement) => void;
  setDirty: () => void;
};

const [useCanvasOffscreen_, CanvasOffscreenContextProvider] = createGenericContext<CanvasOffscreenContext>();

function CanvasOffscreenProvider({ children }: { children?: React.ReactNode }) {
  const app = useLlm(state => state.app);
  const theme = useLlm(state => state.theme);
  const canvasRef = useRef<HTMLCanvasElement>();
  const shaderRef = useRef<Shader>();

  useEffect(() => {
    if (
      app &&
      theme.canvas.enabled &&
      isBrowser &&
      canvasRef.current == null
    ) {
      console.log('CanvasOffscreenProvider', 'theme.canvas.enabled', theme.canvas.enabled);

      if (!sharedCanvas) {
        const pixelRatio = window.devicePixelRatio;
        sharedCanvas = document.createElement('canvas');
        sharedCanvas.width = Math.round(window.innerWidth * pixelRatio);
        sharedCanvas.height = Math.round(window.innerHeight * pixelRatio);
      }
      canvasRef.current = sharedCanvas;

      if (!sharedShader) {
        const colors = {
          color1: theme.color.base[100],
          color2: theme.color.base[200],
          color3: theme.color.base[300],
          color4: theme.color.base[400],
        };
        sharedShader = initShader(sharedCanvas, colors);
        // console.log('CanvasOffscreenProvider.initShader', colors);
      }
      shaderRef.current = sharedShader;

      return () => { };
      // return () => sharedShader!.dispose();
    } else {
      return () => { };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (shaderRef.current) {
      const colors = {
        color1: theme.color.base[100],
        color2: theme.color.base[200],
        color3: theme.color.base[300],
        color4: theme.color.base[400],
      };
      shaderRef.current.setUniforms(colors);
      // console.log('CanvasOffscreenProvider.setUniforms', colors);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [theme, shaderRef]);

  return (
    <CanvasOffscreenContextProvider value={{
      canvasRef,
      shaderRef,
      attach: (canvas) => {
        // console.log('CanvasOffscreenContextProvider.attach', canvas, shaderRef.current);
        if (shaderRef.current) {
          shaderRef.current.attach(canvas);
        }
      },
      detach: (canvas) => {
        if (shaderRef.current) {
          shaderRef.current.detach(canvas);
        }
      },
      setDirty: () => {
        if (shaderRef.current) {
          shaderRef.current.setDirty();
        }
      },
    }}>
      {children}
    </CanvasOffscreenContextProvider>
  );
}

const useCanvasOffscreen = (canvasRef: RefObject<HTMLCanvasElement>) => {
  const canvasOffscreen = useCanvasOffscreen_();
  const pixelRatio = window.devicePixelRatio;
  useEffect(() => {
    // console.log('useCanvasOffscreen', canvasRef.current);
    if (canvasRef.current != null && isBrowser) {
      const canvas = canvasRef.current;
      const onResize = ([entry]: ResizeObserverEntry[]) => {
        // console.log('onResize', entry.target);
        const canvasWidth = entry.contentRect.width;
        const canvasHeight = entry.contentRect.height;
        canvas.width = Math.round(canvasWidth * pixelRatio);
        canvas.height = Math.round(canvasHeight * pixelRatio);
        canvasOffscreen.setDirty();
      };
      const resizeObserver = new ResizeObserver(onResize);
      resizeObserver.observe(canvas);
      canvasOffscreen.attach(canvasRef.current);
      return () => {
        canvasOffscreen.detach(canvas);
        resizeObserver.disconnect();
      };
    } else {
      return () => { };
    }
  }, [canvasOffscreen, canvasRef, pixelRatio]);
};

export { CanvasOffscreenProvider, useCanvasOffscreen };

