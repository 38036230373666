import { getClassNames } from '@websolutespa/bom-core';
import { useLayout, useMounted } from '@websolutespa/bom-mixer-hooks';
import { AnimatePresence } from 'framer-motion';
import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { LlmInstance } from '../types';
import { useLlm } from '../useLlm/useLlm';
import { useLlmView } from '../useLlm/useLlmView';
import { CanvasOffscreenProvider } from './components/CanvasOffscreen/canvas-offscreen';
import { Font } from './components/Font/font';
import { Main } from './components/Main/main';
import { Trigger } from './components/Trigger/trigger';
import './scss/style.scss';

type LlmProps = {
};

export const LlmComponent = forwardRef<LlmInstance, LlmProps>((props: LlmProps, ref) => {
  const innerRef = useRef<HTMLDivElement>(null);
  const mounted = useMounted();
  const { locale } = useLayout();
  const { init } = useLlm(state => state.actions);
  const ready = useLlm(state => state.ready);
  const app = useLlm(state => state.app);
  const theme = useLlm(state => state.theme);
  const embed = useLlm(state => state.embed);
  const { initView, initIntersection, open } = useLlmView(state => state.actions);
  const skipCustomIntro = useLlmView(state => state.skipCustomIntro);
  const idle = useLlmView(state => state.idle);
  const opened = useLlmView(state => state.opened);
  const introed = useLlmView(state => state.introed);
  const embedded = useLlmView(state => state.embedded);

  useImperativeHandle(ref, () => ({
    open: () => {
      open();
    },
  }));

  useEffect(() => {
    init(locale);
    initView();
  }, [init, locale, initView]);

  useEffect(() => {
    if (mounted && ready && innerRef.current) {
      initIntersection(innerRef.current);
    }
  }, [mounted, ready, initIntersection, innerRef]);

  useEffect(() => {
    if (!embedded) {
      document.documentElement.style.overflow = opened ? 'hidden' : 'auto';
    }
  }, [opened, embedded]);

  const shouldShowTrigger = (!opened && !idle && !embed) || (app?.contents.customIntro && !introed);

  const classNames = getClassNames('llm', {
    '-open': opened,
    '-embedded': embedded,
    '-custom-intro': app?.contents.customIntro && !skipCustomIntro,
    ['-' + theme.mode]: true,
  });

  if (mounted && ready) {
    return (
      <>
        <CanvasOffscreenProvider>
          <Font />
          <section ref={innerRef} className={classNames} {...props}>
            <AnimatePresence mode="wait" initial={false}>
              {opened && (
                <Main key="main" />
              )}
            </AnimatePresence>
            <AnimatePresence mode="wait" initial={false}>
              {shouldShowTrigger && (
                <Trigger key="trigger" />
              )}
            </AnimatePresence>
          </section>
        </CanvasOffscreenProvider>
      </>
    );
  } else {
    return <></>;
  }
});

LlmComponent.displayName = 'LlmComponent';
