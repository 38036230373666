import { isBrowser } from '@websolutespa/bom-core';
import { useLabel } from '@websolutespa/bom-mixer-hooks';
import { useState } from 'react';
import { LlmChunkError } from 'src/types';
import { Text } from '../Text/text';

const USE_INSPECT_PARAM = false;

function hasInspect() {
  if (!isBrowser) {
    return false;
  }
  const url = new URL(window.location.href);
  return url.searchParams.has('inspect');
}

export const StreamError = (props: LlmChunkError) => {
  const label = useLabel();
  const [show, setShow] = useState(false);
  const shouldShowStreamError = USE_INSPECT_PARAM ? hasInspect() : true;
  return shouldShowStreamError ? (
    <div className="llm__stream-error llm__row">
      <div className="llm__stream-error__info llm__col --sm-10 --md-8 --lg-6">
        <Text body={label('llm.streamError')} />
        <button
          className="llm__stream-error__toggle"
          type="button"
          onClick={() => setShow(!show)}
          aria-label={show ? label('llm.hideError') : label('llm.showError')}
          aria-pressed={show}
        >{show ? label('llm.hideError') : label('llm.showError')}</button>
      </div>
      {show && (
        <code className="llm__stream-error__error llm__hint">{JSON.stringify(props.error)}</code>
      )}
    </div>
  ) : undefined;
};
