import { useLabel } from '@websolutespa/bom-mixer-hooks';
import { Text } from '../Text/text';

export const FormRecapSuccess = () => {
  const label = useLabel();

  return (
    <div className="llm__row">
      <div className="llm__col --sm-10 --md-8 --lg-6">
        <Text body={label('llm.formRecapSuccess')} />
      </div>
    </div>
  );
};
