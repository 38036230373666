
/*
<link href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap" rel="stylesheet">
*/
import { isBrowser } from '@websolutespa/bom-core';
import { useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import { useLlm } from '../../../useLlm/useLlm';

export const Font = () => {
  const theme = useLlm(state => state.theme);

  useEffect(() => {
    if (isBrowser) {
      const head = document.querySelector('head');
      if (head) {
        const fontId = uuid();
        const htmlText = theme.font.source || `
<link rel="stylesheet" href="https://design.wslabs.it/llm/fonts/aeonik.css">
<link rel="stylesheet" href="https://design.wslabs.it/llm/fonts/capraia.css">
        `;
        const parser = new DOMParser();
        const parsedDocument = parser.parseFromString(htmlText, 'text/html');
        const nodes = Array.from(parsedDocument.querySelectorAll('head>*'));
        const selector = `llm-font-${fontId}`;
        nodes.forEach(node => {
          node.classList.add(selector);
          head.appendChild(node);
          console.log('[mixer-llm] added font node', node);
        });
        return () => {
          const addedNodes = Array.from(document.querySelectorAll(`.${selector}`));
          addedNodes.forEach(node => {
            if (node.parentNode) {
              node.parentNode.removeChild(node);
              console.log('[mixer-llm] removed font node', node);
            }
          });
        };
      }
    }
    return () => { };
  }, [theme.font.source]);
  return (<></>);
};

export function getSeed(text: string, length: number = 32): string {
  let seed = '';
  for (let i = 0; i < text.length; i++) {
    if (seed.length < length) {
      seed += text.charCodeAt(i).toString(16);
    }
  }
  return seed.padEnd(length, '0');
}
