import { DeepPartial, IMedia } from '@websolutespa/bom-core';
import { defaultTheme } from './llm/theme';

export type LlmChunkLog = Record<string, unknown> & {
  type: 'log';
};

export type LlmChunkError = {
  type: 'error';
  error: unknown;
};

export type LlmChunkInfo = {
  type: 'info';
  threadId: string;
};

export type LlmChunkEnd = {
  type: 'end';
};

export type LlmChunkText = {
  type: 'text';
  text: string;
};

export type LlmChunkString = {
  type: 'string';
  content: string;
}; // deprecated

export type LlmChunkTextOrString = LlmChunkText | LlmChunkString;

export function isChunkText(chunk: LlmChunkItem): chunk is LlmChunkText {
  return chunk.type === 'text';
}

export function isChunkString(chunk: LlmChunkItem): chunk is LlmChunkString {
  return chunk.type === 'string';
}

export function isChunkTextOrString(chunk: LlmChunkItem): chunk is LlmChunkTextOrString {
  return chunk.type === 'text' || chunk.type === 'string';
}

export type ImageUrl = {
  url: string;
};

export type LlmChunkImageUrl = {
  type: 'image_url';
  image_url: ImageUrl
};

export type LlmChunkImage = {
  type: 'image';
  id: string;
  src: string;
};

// cards

export type LlmMediaImage = {
  type: 'image';
  src: string;
};

export type LlmMediaVideo = {
  type: 'video';
  src: string;
};

export type LlmMediaThron = {
  type: 'thron';
  clientId: string;
  xcontentId: string;
  sessId: string;
};

export type LlmMedia = LlmMediaImage | LlmMediaVideo | LlmMediaThron;

// cards

export type LlmChunkCardBase = {
  type: 'card';
  id: string;
  date?: string;
  title: string;
  abstract: string;
  media: LlmMedia;
  cta: {
    url?: string;
    label?: string;
  };
};

export type LlmChunkCardImage = LlmChunkCardBase & {
  cardType: 'image';
  media: LlmMediaImage;
};

export type LlmChunkCardFull = LlmChunkCardBase & {
  cardType: 'full';
  media: LlmMediaImage;
};

export type LlmChunkCardVideo = LlmChunkCardBase & {
  cardType: 'video';
  media: LlmMediaVideo;
};

export type LlmChunkCardThron = LlmChunkCardBase & {
  cardType: 'thron';
  media: LlmMediaThron;
};

export type LlmChunkCard = LlmChunkCardImage | LlmChunkCardFull | LlmChunkCardVideo | LlmChunkCardThron;

export type LlmChunkCardItem = Omit<LlmChunkCard, 'type'> & { type: 'cardItem' };

export type LlmChunkCardGroup = {
  type: 'cardGroup';
  items: LlmChunkCardItem[];
};

// products

export type LlmChunkProductBase = {
  type: 'product';
  id: string;
  title: string;
  abstract?: string;
  sku?: string;
  currency?: string;
  price?: string | number;
  priceLabel?: string;
  fullPrice?: string | number;
  fullPriceLabel?: string;
  availability?: string | number;
  availabilityLabel?: string;
  label?: string;
  media: LlmMedia;
  url?: string;
  cta?: string;
};

export type LlmChunkProductSimple = LlmChunkProductBase & {
  productType: 'simple';
  media: LlmMediaImage;
};

export type LlmChunkProductConfigurable = LlmChunkProductBase & {
  productType: 'configurable';
  media: LlmMediaImage;
};

export type LlmChunkProduct = LlmChunkProductSimple | LlmChunkProductConfigurable;

export type LlmChunkProductItem = Omit<LlmChunkProduct, 'type'> & { type: 'productItem' };

export type LlmChunkProductGroup = {
  type: 'productGroup';
  items: LlmChunkProductItem[];
};

// events

export type LlmChunkEvent = {
  type: 'event';
  id: string;
  title: string;
  abstract: string;
  place: string;
  url: string;
  image: string;
  date?: string;
  time?: string;
};

export type LlmChunkEventItem = Omit<LlmChunkEvent, 'type'> & { type: 'eventItem' };

export type LlmChunkEventGroup = {
  type: 'eventGroup';
  items: LlmChunkEventItem[];
};

// pois

export type LlmChunkPoi = {
  type: 'poi';
  id: string;
  title: string;
  abstract: string;
  time?: string;
  place: string;
  url: string;
  image: string;
};

export type LlmChunkPoiItem = Omit<LlmChunkPoi, 'type'> & { type: 'poiItem' };

export type LlmChunkPoiGroup = {
  type: 'poiGroup';
  items: LlmChunkPoiItem[];
};

// tripadvisor

export type LlmChunkTripadvisor = {
  type: 'tripadvisor';
  id: string;
  title: string;
  rating: number;
  reviews: number;
  priceRange: string;
  cuisine: string;
  address: string;
  url: string;
  book: string;
  image: string;
};

export type LlmChunkTripadvisorItem = Omit<LlmChunkTripadvisor, 'type'> & { type: 'tripadvisorItem' };

export type LlmChunkTripadvisorGroup = {
  type: 'tripadvisorGroup';
  items: LlmChunkTripadvisorItem[];
};

// form

export type LlmChunkFormRequest = {
  type: 'formRequest';
};

export type LlmChunkFormRecap = {
  type: 'formRecap';
};

export type LlmChunkFormRecapSuccess = {
  type: 'formRecapSuccess';
};

export type LlmChunkFormRecapError = {
  type: 'formRecapError';
};

// known items

export type LlmChunkKnownItem =
  LlmChunkEnd
  | LlmChunkCard
  | LlmChunkCardGroup
  | LlmChunkCardItem
  | LlmChunkProduct
  | LlmChunkProductGroup
  | LlmChunkProductItem
  | LlmChunkEvent
  | LlmChunkEventGroup
  | LlmChunkEventItem
  | LlmChunkError
  | LlmChunkFormRequest
  | LlmChunkFormRecap
  | LlmChunkFormRecapSuccess
  | LlmChunkFormRecapError
  | LlmChunkImage
  | LlmChunkInfo
  | LlmChunkLog
  | LlmChunkPoi
  | LlmChunkPoiGroup
  | LlmChunkPoiItem
  | LlmChunkImageUrl
  | LlmChunkTripadvisor
  | LlmChunkTripadvisorGroup
  | LlmChunkTripadvisorItem
  | LlmChunkText
  | LlmChunkString;

export type LlmChunkUnknown = Record<string, unknown> & { type: Exclude<string, LlmChunkKnownItem['type']> };

export type LlmChunkItem = LlmChunkKnownItem;

export type LlmChunkType = Exclude<LlmChunkKnownItem['type'], 'string' | 'text' | 'image_url' | 'info' | 'end' | 'formRequest' | 'formRecap' | 'formRecapSuccess' | 'formRecapError'>;

export type LlmItem = Extract<LlmChunkItem, { type: LlmChunkType }>;

// export type ToDiscriminatedUnion<T> = { [K in keyof T]: { type: K, value: T[K] } }[keyof T];
// type StuctureMappedType<T extends LlmChunkType> = { [K in T]: Array<LlmChunkItem<K>> };
// export type LlmChunkItems<T extends LlmChunkType = LlmChunkType> = Record<T, (item: Extract<LlmChunkItem, { type: T }>) => string>;

export type LlmChunk = string | LlmChunkItem;

export type LlmCredentials = {
  apiKey: string;
  appKey: string;
};

export type LlmMessage = {
  role: string; // assistant, user
  content: string | LlmChunkItem[];
};

export type LlmSerializedMessage = {
  role: string; // assistant, user
  content: string;
};

export type LlmDecodedMessage = LlmMessage & {
  chunks: LlmChunkItem[];
};

export type StreamRequest = LlmCredentials & {
  messages: LlmMessage[];
  threadId?: string;
};

export type StreamResponse = {
  chunks: LlmChunkItem[];
  threadId: string;
};

export type LlmCard = {
  id?: string;
  blockType?: string;
  icon?: IMedia;
  body: string;
  title: string;
  message?: string;
};

export type LlmSampleInputText = {
  id: string;
  sampleInputText: string;
};

export type LlmTheme = typeof defaultTheme;

export type LlmRecognitionMode = ('none' | 'default') & string;
export type LlmSynthesisMode = ('none' | 'default' | 'elevenlabs') & string;

export type LlmContents = {
  additionalLogos?: { logo: IMedia }[];
  collapsedWelcomeText?: string;
  collapsedWelcomeTextCta?: string;
  collapsedWelcomeTextHover?: string;
  customIntro?: string;
  customTheme?: DeepPartial<LlmTheme>;
  disableSpeechRecognition?: boolean;
  disableSpeechSynthesis?: boolean;
  disclaimer?: string;
  enableUpload?: boolean;
  extendedWelcomeText?: string;
  intro?: string;
  layoutBuilder?: LlmCard[];
  logo?: IMedia;
  logoMini?: IMedia;
  openingSound?: IMedia;
  promptPlaceholder?: string;
  recognitionMode?: LlmRecognitionMode;
  sampleInputTexts: LlmSampleInputText[];
  shortWelcomeText?: string;
  synthesisMode?: LlmSynthesisMode;
  textToSpeechApiKey?: string;
  textToSpeechVoiceId?: string;
};

export type LlmThread = {
  messages: LlmMessage[];
  threadId: string;
};

export type LlmDecodedThread = {
  messages: LlmDecodedMessage[];
  threadId: string;
};

export type LlmWebhook = {
  webhook: string;
};

export type LlmWebhooks = LlmWebhook[];

export type LlmMode = ('site' | 'chat' | 'url2text' | 'translation') & string;

export type LlmApp = {
  mode: LlmMode;
  contents: LlmContents;
  thread?: LlmThread;
  webhooks?: LlmWebhooks;
};

export type LlmMockApp = {
  mode: LlmMode;
  contents: LlmContents;
} | Record<string, {
  mode: LlmMode;
  contents: LlmContents;
}>;

export type LlmMockThreadMessage = {
  chunks: LlmChunk[];
  role: string;
};

export type LlmMockThread = LlmMockThreadMessage[] | Record<string, LlmMockThreadMessage[]>;

export type LlmMock = {
  app: LlmMockApp;
  thread: LlmMockThread;
};

export type LlmTest = undefined | boolean | DeepPartial<LlmMock>;

export type LlmVars = { [key: string]: string };

export type LlmInstance = {
  open: () => void;
};

export type LlmUiStatus = {
  open: boolean;
};
