import { getClassNames } from '@websolutespa/bom-core';
import { useLabel } from '@websolutespa/bom-mixer-hooks';
import { IconLlmArrowCornerRightUp } from '@websolutespa/bom-mixer-icons';
import { LlmApp } from '../../../types';
import { useLlm } from '../../../useLlm/useLlm';
import { useLlmView } from '../../../useLlm/useLlmView';

type HeaderProps = {
};

export const Header = (props: HeaderProps) => {
  const app: LlmApp | undefined = useLlm(state => state.app);
  const { open } = useLlmView(state => state.actions);
  const hidden = useLlmView(state => state.hidden);
  const fixed = useLlmView(state => state.fixed);
  const dismissable = useLlmView(state => state.dismissable);
  const label = useLabel();
  const logo = app?.contents.logo;
  const logoMini = app?.contents?.logoMini;
  const additionalLogos = app?.contents?.additionalLogos;
  const classes = getClassNames('llm__header', { hidden, fixed });
  return (
    <nav className={classes}>
      <div className="llm__header-wrapper">
        <div className="llm__header-logo">
          {logo && (
            <div className="llm__header-logo-full">
              <img src={logo.src || logo.url} alt={logo.alt} />
            </div>
          )}
          {additionalLogos && (
            additionalLogos.map((x, i) => (
              <div key={i} className="llm__header-logo-additional">
                <img src={x.logo.src || x.logo.url} alt={x.logo.alt} />
              </div>
            ))
          )}
          {logoMini && (
            <div className="llm__header-logo-mini">
              <img src={logoMini.src || logoMini.url} alt={logoMini.alt} />
            </div>
          )}
        </div>
        <a href="#llm-prompt-textarea" className="llm__header-skip">{label('llm.skip')}</a>
        {dismissable && (
          <button type="button" className="llm__header-back -llm-t-cta3" onClick={() => open()}>
            {label('llm.back')}
            <IconLlmArrowCornerRightUp />
          </button>
        )}
      </div>
    </nav>
  );
};
