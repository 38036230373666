import { getClassNames } from '@websolutespa/bom-core';
import { LlmChunkPoiGroup } from '../../../types';
import { Poi } from '../Poi/poi';

export const PoiGroup = (props: LlmChunkPoiGroup) => {
  const classNames = getClassNames('llm__pois');
  return (
    <div className={classNames}>
      {props.items.map((item, i) => (
        <Poi key={i} {...item} />
      ))}
    </div>
  );
};

