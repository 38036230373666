import { useEffect } from 'react';

export function useAutosizeTextArea(
  textAreaRef: HTMLTextAreaElement | null,
  value: string
) {
  useEffect(() => {
    if (textAreaRef) {
      textAreaRef.style.minHeight = '0px';
      textAreaRef.style.minHeight = textAreaRef.scrollHeight + 'px';
    }
  }, [textAreaRef, value]);
}
