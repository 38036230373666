import { getClassNames } from '@websolutespa/bom-core';
import { useLabel } from '@websolutespa/bom-mixer-hooks';
import React from 'react';
import { useLlm } from '../../../useLlm/useLlm';
import { Chunk } from '../Chunk/chunk';
import { Disclaimer } from '../Disclaimer/disclaimer';
import { Stream } from '../Stream/stream';

export type ThreadProps = {
};

export const Thread: React.FC<ThreadProps> = () => {
  const label = useLabel();
  const messages = useLlm(state => state.messages);
  return (
    <section className="llm__thread llm__container">
      {messages.map((message, m) => (
        <div
          key={`llm-message-${m}`}
          className={getClassNames('llm__message', `llm__message--${message.role}`)}
          role="region"
          aria-label={message.role === 'user' ? label('llm.question') : label('llm.answer')}>
          {message.chunks.map((chunk, c) => (
            <div key={`llm-message-${m}-chunk-${c}`} className={`llm__inner llm__inner--${chunk.type}`} data-index={m}>
              <Chunk item={chunk} chunkIndex={c} index={m} total={messages.length} role={message.role} />
            </div>
          ))}
          {message.role === 'user' && m === 0 && (<Disclaimer />)}
        </div>
      ))}
      <Stream index={messages.length} />
    </section>
  );
};
