import { useLabel } from '@websolutespa/bom-mixer-hooks';
import { createContext, useContext, useRef } from 'react';
import { createStore, useStore } from 'zustand';
import { createJSONStorage, persist, StateStorage } from 'zustand/middleware';
import { getTheme } from '../llm/theme';
import { createVars, getVars } from '../llm/vars';
import { LlmApp, LlmChunk, LlmChunkItem, LlmChunkKnownItem, LlmDecodedMessage, LlmDecodedThread, LlmItem, LlmTest, LlmTheme, LlmThread, LlmVars, StreamResponse } from '../types';
import { ApiService } from './api.service';
import { MessageDecoder, MessageService } from './message.service';
import { getMock } from './mock';
import { chunksToTexts, Speech } from './speech';
import { LlmViewOptions, LlmViewProvider } from './useLlmView';

export type LlmDecorateUrl = (item: LlmItem) => (string | void) | Promise<string | void>;

export type LlmOptions = {
  apiKey: string;
  appKey: string;
  decorateUrl?: LlmDecorateUrl;
  endpoint?: string;
  storage?: StateStorage;
  test?: LlmTest;
  customTheme?: Partial<LlmTheme>;
} & LlmViewOptions;

export type LlmProps = {
  app?: LlmApp;
  chunks?: LlmChunkItem[];
  locale: string;
  messages: LlmDecodedMessage[];
  prompt: string;
  speakEnabled: boolean;
  streaming: boolean;
  theme: LlmTheme;
  threadId?: string;
  vars: LlmVars;
  embed?: Element | undefined;
  hydrated: boolean;
  ready: boolean;
};

export type LlmActions = {
  init: (locale: string) => Promise<void>;
  send: (message: string, onPrompt?: () => void, onMessage?: (response: StreamResponse) => void, onEnd?: (response: StreamResponse) => void) => Promise<void>;
  setPrompt: (prompt: string) => void;
  hasSpeechSynthesisSupport: () => boolean;
  hasSpeechRecognitionSupport: () => boolean;
  recognizeStart: () => void;
  recognizeStop: () => void;
  formRequest: (choice: boolean) => void;
  formRecap: (error?: unknown) => void;
  decorateUrl: (item: LlmItem, url?: string) => Promise<string>;
  clear: () => void;
  toggleSpeak: () => void;
  abort: () => void;
  setVars: (vars: Partial<LlmVars>) => void;
  setEmbed: (element: Element, active: boolean) => void;
  getApi: () => ApiService;
};

export type LlmState = LlmProps & {
  actions: LlmActions;
};

export type LlmStore = ReturnType<typeof createLlmStore>;

const createLlmStore = ({
  appKey,
  apiKey,
  threadId,
  decorateUrl,
  endpoint,
  storage,
  test,
  preview,
  customTheme,
  label,
}: LlmOptions & {
  label: (key: string) => string
}) => {

  /**
   * test remote api
   * appKey = 'xxxxxxxxxxxxxx';
   * apiKey = 'xxxxxxxxxxxxxx';
   * endpoint = 'https://platform.websolute.ai';
   * endpoint = 'http://localhost:4000/bowl';
   * test = false;
   */

  const theme = getTheme(customTheme);
  const vars = getVars(theme);

  const props: LlmProps = {
    hydrated: false,
    theme,
    vars,
    messages: [],
    prompt: '',
    locale: 'en',
    streaming: false,
    speakEnabled: true,
    ready: false,
  };

  let messageService: MessageService | undefined = undefined;

  const speech = new Speech();

  const useStore = createStore<LlmState>()(
    persist(
      (set, get) => ({
        ...props,
        actions: {
          getApi: () => {
            const state = get();
            const { locale, threadId } = state;
            const apiService = new ApiService({
              apiKey,
              appKey,
              threadId,
              endpoint,
              locale,
              test,
              preview,
            });
            return apiService;
          },
          init: async (locale: string) => {
            const apiService = new ApiService({
              apiKey,
              appKey,
              threadId,
              endpoint,
              locale,
              test,
              preview,
            });
            const app = await apiService.getInfo();
            if (!app) {
              return;
            }

            const state = get();
            const textToSpeechApiKey = app.contents.textToSpeechApiKey;
            const textToSpeechVoiceId = app.contents.textToSpeechVoiceId;
            const synthesisMode = app.contents.synthesisMode ||
              (app.contents.disableSpeechSynthesis ? 'none' : 'default');
            const recognitionMode = app.contents.recognitionMode ||
              (app.contents.disableSpeechRecognition ? 'none' : 'default');
            const speechEnabled = state.speakEnabled;
            speech.lang = locale;
            speech.enabled = speechEnabled;
            speech.textToSpeechApiKey = textToSpeechApiKey;
            speech.textToSpeechVoiceId = textToSpeechVoiceId;
            speech.synthesisMode = synthesisMode;
            speech.recognitionMode = recognitionMode;
            // console.log('useLlm.init', { textToSpeechApiKey, textToSpeechVoiceId, synthesisMode, recognitionMode, speechEnabled });
            speech.on('result', async (transcript: string) => {
              const state = get();
              state.actions.setPrompt(transcript);
            });
            const decoder = new MessageDecoder();
            const decodeThread = (thread?: LlmThread): LlmDecodedThread | undefined => {
              return thread ? {
                messages: decoder.decodeMessages(thread.messages),
                threadId: thread.threadId,
              } : undefined;
            };
            const thread = decodeThread(app.thread);
            const testTheme = test ? getMock(locale, test).app?.contents?.customTheme : {};
            /*
            console.log(
              'appTheme', app.contents.customTheme,
              'testTheme', testTheme,
              'customTheme', customTheme
            );
            */
            const theme = getTheme(app.contents.customTheme, testTheme, customTheme);
            /*
            console.log(
              'theme', theme
            );
            */
            const vars = getVars(theme);
            createVars(vars);
            // console.log('thread', thread);
            set(() => ({ app, theme, vars, locale, ...thread, ready: true }));
          },
          send: async (prompt, onPrompt, onMessage, onEnd) => {
            const state = get();
            messageService = new MessageService({
              apiKey,
              appKey,
              endpoint,
              locale: state.locale,
              test,
            });
            const messages = await messageService.addUserMessage(state.messages, prompt);
            set(state => ({
              messages,
              chunks: [],
              prompt: '',
              streaming: true,
            }));
            if (typeof onPrompt === 'function') {
              onPrompt();
            }
            const request = await messageService.getRequest(messages, state.threadId);
            await messageService.sendMessage(request,
              // onMessage
              (response: StreamResponse) => {
                // console.log('messageService.sendMessage.onMessage', response.chunks);
                set(state => ({
                  chunks: response.chunks,
                }));
                if (typeof onMessage === 'function') {
                  onMessage(response);
                }
              },
              // onEnd
              (response: StreamResponse) => {
                // console.log('messageService.sendMessage.onEnd', response.chunks);
                const chunks = [...response.chunks];
                const assistantMessage = {
                  chunks,
                  content: '',
                  role: 'assistant',
                };
                const responseMessages = [...messages, assistantMessage];
                if (messageService && messageService.shouldAddFormRequest(responseMessages)) {
                  chunks.push({
                    type: 'formRequest',
                  });
                }
                // filtering history
                const validChunks = chunks.filter(x => !['info', 'end', 'formRequest', 'formRecap', 'formRecapSuccess', 'formRecapError'].includes(x.type));
                const parsedChunks: LlmChunkKnownItem[] = [];
                validChunks.reduce((p, c) => {
                  const firstChunk = p.length > 1 && p[p.length - 2];
                  const secondChunk = p.length > 1 && p[p.length - 1];
                  if (
                    firstChunk &&
                    secondChunk &&
                    c.type === 'string' && firstChunk.type === 'string' && secondChunk.type === 'string') {
                    secondChunk.content += c.content;
                  } else {
                    p.push({ ...c });
                  }
                  return p;
                }, parsedChunks);
                assistantMessage.content = parsedChunks.map(x => JSON.stringify(x)).join(',') + ',';
                set(state => ({
                  chunks: undefined,
                  messages: responseMessages,
                  threadId: response.threadId,
                  streaming: false,
                }));
                speech.reader.end();
                if (typeof onEnd === 'function') {
                  onEnd(response);
                }
              },
              // onChunk
              (chunks: LlmChunk[]) => {
                // console.log('onChunk', chunks);
                const texts = chunksToTexts(chunks);
                texts.forEach(text => speech.reader.add(text));
              });
          },
          abort: () => {
            if (messageService) {
              messageService.abort();
              messageService = undefined;
            }
          },
          setVars: (vars) => set(state => ({ vars: Object.assign({}, state.vars, vars) })),
          setEmbed: (element: Element, active: boolean) => {
            const embed = get().embed;
            if (!active && embed !== element) {
              return;
            }
            set(state => {
              return { embed: active ? element : undefined };
            });
          },
          setPrompt: (prompt) => set(state => ({ prompt })),
          hasSpeechSynthesisSupport: () => {
            const state = get();
            return speech.hasTextToSpeechSupport;
          },
          hasSpeechRecognitionSupport: () => {
            const state = get();
            return speech.hasSpeechToTextSupport;
          },
          toggleSpeak: () => {
            set(state => {
              const speakEnabled = !state.speakEnabled;
              speech.setEnabled(speakEnabled);
              return { speakEnabled };
            });
          },
          recognizeStart: () => {
            const state = get();
            speech.recognizeStart();
          },
          recognizeStop: () => {
            const state = get();
            speech.recognizeStop();
          },
          formRequest: (choice) => {
            const choiceMessage = choice ? label('llm.formRequestYes') : label('llm.formRequestNo');
            const userMessage = {
              chunks: [{
                type: 'string' as const,
                content: choiceMessage,
              }],
              content: choiceMessage,
              role: 'user',
            };
            const messages: LlmDecodedMessage[] = [userMessage];
            if (choice) {
              const assistantMessage = {
                chunks: [{
                  type: 'formRecap' as const,
                }],
                content: '',
                role: 'assistant',
              };
              messages.push(assistantMessage);
            }
            set(state => ({
              messages: [...state.messages, ...messages],
            }));
          },
          formRecap: (error) => {
            if (error) {
              console.warn('useLlm.formRecap', error);
            }
            const messages: LlmDecodedMessage[] = [];
            const assistantMessage = {
              chunks: [{
                type: error ? 'formRecapError' as const : 'formRecapSuccess' as const,
              }],
              content: '',
              role: 'assistant',
            };
            messages.push(assistantMessage);
            set(state => ({
              messages: [...state.messages, ...messages],
            }));
          },
          decorateUrl: async (item, defaultUrl?: string) => {
            let url;
            if (typeof decorateUrl === 'function') {
              url = await decorateUrl(item);
            }
            return url || defaultUrl || '#';
          },
          clear: () => set(state => ({ messages: [] })),
        },
      }),
      {
        name: 'llm',
        storage: createJSONStorage(() => storage || localStorage),
        onRehydrateStorage: () => () => {
          useStore.setState({ hydrated: true });
        },
        merge: (persistedState: any, currentState: LlmState) => {
          if (persistedState.app) {
            currentState.app = { ...persistedState.app };
          }
          currentState.speakEnabled = persistedState.speakEnabled || false;
          speech.enabled = currentState.speakEnabled;
          /*
          if (persistedState.messages) {
            currentState.messages = [...persistedState.messages];
          }
          */
          currentState.hydrated = true;
          return currentState;
        },
        partialize: (state) => Object.fromEntries(
          Object.entries(state)
            .filter(([key]) => ['locale', 'speakEnabled'].includes(key))
        ),
      }
    )
  );
  return useStore;
};

let sharedLlmStore: LlmStore | undefined;
function createSharedLlmStore(props: LlmOptions & { label: (key: string) => string }): LlmStore {
  if (!sharedLlmStore) {
    sharedLlmStore = createLlmStore(props);
  }
  return sharedLlmStore;
}

export const LlmContext = createContext<LlmStore | null>(null);

type LlmProviderProps = React.PropsWithChildren<Omit<LlmOptions, 'locale'>>;

function LlmProvider({ children, ...props }: LlmProviderProps) {
  const label = useLabel();
  if (props.embedded) {
    props.dismissable = false;
    props.opened = true;
  }
  if (props.preview) {
    props.dismissable = false;
    props.opened = true;
    props.skipCustomIntro = true;
  }
  const storeRef = useRef<LlmStore>();
  if (!storeRef.current) {
    storeRef.current = createSharedLlmStore({ ...props, label });
  }
  return storeRef.current && (
    <LlmContext.Provider value={storeRef.current}>
      <LlmViewProvider {...props}>
        {children}
      </LlmViewProvider>
    </LlmContext.Provider>
  );
}

function useLlm<T>(selector: (state: LlmState) => T): T {
  const store = useContext(LlmContext);
  if (!store) throw new Error('Missing LlmContext.Provider in the tree');
  return useStore(store, selector);
}

export { LlmProvider, useLlm };

