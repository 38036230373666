import { motion } from 'framer-motion';

export const BusyIndicator = () => {
  return (
    <motion.div className="llm__busyIndicator"
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 1 }}
    >
      <span></span>
    </motion.div>
  );
};
