import { getClassNames, isBrowser } from '@websolutespa/bom-core';
import { useMounted } from '@websolutespa/bom-mixer-hooks';
import { IconLlmMaximize, IconLlmMinimize, IconLlmPlay } from '@websolutespa/bom-mixer-icons';
import { MutableRefObject, useEffect, useMemo, useRef, useState } from 'react';
import { LlmChunkCardThron } from '../../../types';
import { Dialog } from '../Dialog/dialog';

export type IThronPlayer = {
  play: () => void;
  pause: () => void;
  on: (event: string, handler: (...args: any[]) => void) => void;
  off: (event: string, handler: (...args: any[]) => void) => void;
  fullscreen: () => void;
};

export const StaticNode = ({
  outerRef,
  playing,
  ...props
}: {
  outerRef: MutableRefObject<HTMLDivElement | undefined>;
  playing?: boolean;
  onClose?: () => void;
}) => {
  const innerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (innerRef.current && outerRef.current) {
      outerRef.current.removeAttribute('style');
      innerRef.current.appendChild(outerRef.current);
    }
  }, [innerRef, outerRef]);
  const onClose = () => {
    if (typeof props.onClose === 'function') {
      props.onClose();
    }
  };
  const classNames = getClassNames('llm__thron-player', 'llm__video', '-enlarged', { '-playing': playing });
  return (
    <>
      <div ref={innerRef} className={classNames}></div>
      <button type="button" className="llm__video-reduce" onClick={() => onClose()} aria-label="Reduce">
        <IconLlmMinimize />
      </button>
    </>
  );
};

export const ThronPlayer = (props: LlmChunkCardThron) => {
  const id = useMemo(() => {
    return `video-${props.media.xcontentId}-${Math.round(Math.random() * 10000)}`;
  }, [props.media.xcontentId]);
  const mounted = useMounted();
  const innerRef = useRef<HTMLDivElement>(null);
  const elementRef = useRef<HTMLDivElement>();
  const playerRef = useRef<IThronPlayer>();
  const [ready, setReady] = useState<boolean>(false);
  const [playing, setPlaying] = useState<boolean>(false);
  const [enlarged, setEnlarged] = useState<boolean>(false);
  useEffect(() => {
    if (innerRef.current && elementRef.current && !enlarged) {
      elementRef.current.removeAttribute('style');
      innerRef.current.appendChild(elementRef.current);
    }
  }, [innerRef, elementRef, ready, enlarged]);
  useEffect(() => {
    if (isBrowser && mounted) {
      let element: HTMLDivElement | undefined = undefined;
      let player: IThronPlayer | undefined = undefined;
      const onPlay = () => {
        setPlaying(true);
      };
      const onPause = () => {
        setPlaying(false);
      };
      const onReady = () => {
        elementRef.current = element;
        playerRef.current = player;
        setReady(true);
      };
      const onLoad = () => {
        const options = {
          clientId: props.media.clientId,
          xcontentId: props.media.xcontentId,
          sessId: props.media.sessId,
          autoplay: false,
          muted: false,
          noSkin: false,
        };
        try {
          // console.log('THRONContentExperience', (window as any)['THRONContentExperience']);
          if ('THRONContentExperience' in window) {
            element = document.createElement('div');
            element.setAttribute('id', id);
            element.setAttribute('style', 'width:100vw;height:56.25vw;');
            document.body.appendChild(element);
            const THRONContentExperience = (window as any)['THRONContentExperience'];
            player = THRONContentExperience(id, options) as IThronPlayer;
            // console.log('player', player);
            player.on('play', onPlay);
            player.on('pause', onPause);
            player.on('ready', onReady);
          }
        } catch (error: any) {
          console.log('ThronPlayer.error', error);
        }
      };
      const thronEmbedScript = document.querySelector('#thron-embed-script');
      if (!thronEmbedScript) {
        const script = document.createElement('script');
        script.id = 'thron-embed-script';
        script.src = 'https://legadelfilodoro-cdn.thron.com/shared/ce/bootstrap/1/scripts/embeds-min.js';
        script.async = true;
        script.onload = onLoad;
        document.head.appendChild(script);
      } else {
        onLoad();
      }
      return () => {
        if (playerRef.current) {
          playerRef.current.off('play', onPlay);
          playerRef.current.off('pause', onPause);
          playerRef.current.off('ready', onReady);
        }
      };
    }
    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, mounted]);
  const onClick = () => {
    if (playerRef.current) {
      playerRef.current.play();
    }
  };
  const onEnlarge = () => {
    if (playerRef.current) {
      setEnlarged(true);
    }
  };
  const onReduce = () => {
    if (playerRef.current) {
      setEnlarged(false);
    }
  };
  const classNames = getClassNames('llm__thron-player', 'llm__video', { '-playing': playing });
  return (
    <div className={classNames}>
      <div ref={innerRef}></div>
      <button type="button" className="llm__video-play" onClick={() => onClick()} aria-label="Play">
        <IconLlmPlay />
      </button>
      <button type="button" className="llm__video-enlarge" onClick={() => onEnlarge()} aria-label="Enlarge">
        <IconLlmMaximize />
      </button>
      <Dialog visible={enlarged} onClose={onReduce}>
        <StaticNode outerRef={elementRef} playing={playing} onClose={onReduce} />
      </Dialog>
    </div>
  );
};
