import { getClassNames } from '@websolutespa/bom-core';
import { IconLlmArrowRight } from '@websolutespa/bom-mixer-icons';
import React from 'react';
import { Canvas } from '../Canvas/canvas';

export type LlmCtaType = 'button' | 'text' | 'simple';

export type LlmCta = {
  type: string;
  label: string;
  target?: '_blank' | '_self';
  url?: string;
  onClick?: (event: React.MouseEvent) => void;
};

export const Cta = (props: LlmCta) => {
  const onClick = (event: React.MouseEvent) => {
    if (typeof props.onClick === 'function') {
      props.onClick(event);
    }
  };
  const bg = props.type == 'button' || props.type == 'submit' ? <Canvas /> : '';
  const icon = props.type == 'text' ? <IconLlmArrowRight /> : '';
  const classNames = getClassNames('llm__cta', `llm__cta--${props.type}`);
  return props.url ? (
    <a className={classNames} href={props.url} target={props.target}>
      {bg}<span>{props.label}</span>{icon}
    </a>
  ) : (
    <button className={classNames} onClick={onClick} type={props.type == 'button' || props.type == 'submit' ? props.type : undefined}>
      {bg}
      <span>{props.label}</span>
      {icon}
    </button>
  );
};
