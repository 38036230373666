import { marked } from 'marked';
import { useEffect, useMemo, useRef } from 'react';
import { useLlmView } from '../../../useLlm/useLlmView';

// !!! todo: change marked with -> https://github.com/remarkjs/react-markdown?tab=readme-ov-file#use-a-plugin

type LlmTextProps = {
  body: string;
  chunkIndex?: number;
  index?: number;
};

export const Text = (props: LlmTextProps) => {
  const { send } = useLlmView(state => state.actions);
  const chunkIndex = props.chunkIndex !== undefined ? props.chunkIndex : -1;
  const htmlRef = useRef<HTMLDivElement>(null);
  const { html, hasBlockElements } = useMemo(() => {
    marked.use({
      renderer: {
        link(href: string, title: string | null | undefined, text: string) {
          return `<a class="llm__text-link" href="${href}" target="_blank" rel="noreferrer"
          ${title ? `title="${title}"` : ''}
          onclick="javascript:event.preventDefault(),window.open(event.currentTarget.href);">
            ${text}
          </a>`;
        },
        image(href: string, title: string | null | undefined, text: string) {
          return `<a class="llm__text-img" href="${href}" target="_blank" rel="noreferrer"
          onclick="javascript:event.preventDefault(),window.open(event.currentTarget.href);">
            <img src="${href}"${title ? ` title="${title}"` : ''} alt="" />
          </a>`;
        },
        /*
        html(html: string, block?: boolean) {
          console.log('html', html);
          return html;
        },
        */
      },
    });
    let hasBlockElements = false;
    const html = marked.parse(props.body, {
      walkTokens: (token) => {
        // console.log('walkTokens', token);
        if (!['text', 'strong', 'em', 'paragraph', 'span', 'space'].includes(token.type)) {
          hasBlockElements = true;
        }
      },
    });
    return { html, hasBlockElements };
  }, [props.body]);
  useEffect(() => {
    let pills: HTMLDivElement[] = [];
    if (htmlRef.current) {
      pills = Array.from(htmlRef.current.querySelectorAll('.llm__pill'));
    }
    const pillClickHandler = async (event: MouseEvent) => {
      if (event.currentTarget) {
        await send((event.currentTarget as HTMLDivElement).innerText, (response) => {
          // console.log('Suggestions.onMessage', response.chunks);
        }, (response) => {
          // console.log('Suggestions.onEnd', response);
        });
      }
    };
    pills.forEach(x => x.addEventListener('click', pillClickHandler));
    return () => {
      pills.forEach(x => x.removeEventListener('click', pillClickHandler));
    };
  }, [props.body, send]);
  const isHeading = chunkIndex === 0 && !hasBlockElements;
  return (
    <div className="llm__text">
      {isHeading ? (
        <h2 ref={htmlRef} className="llm__text-body" dangerouslySetInnerHTML={{ __html: html }} />
      ) : (
        <div ref={htmlRef} className="llm__text-body" dangerouslySetInnerHTML={{ __html: html }} />
      )}
    </div>
  );
};
