
export function ErrorHandler({ error, resetErrorBoundary }: { error: any, resetErrorBoundary?: () => void }) {
  console.log('ErrorHandler.error', error);
  return (
    <div style={{ minHeight: '100vh', background: '#101010', color: '#eeeeee' }}>
      <div>
        <div style={{ display: 'flex', gap: '1rem', alignItems: 'flex-start' }}>
          <div>Unhandled Runtime Error</div>
          <div>{error.message}</div>
          {error.stack && (
            <div>{error.stack}</div>
          )}
          {typeof resetErrorBoundary === 'function' && (
            <button onClick={resetErrorBoundary}>Try again?</button>
          )}
        </div>
      </div>
    </div>
  );
}
