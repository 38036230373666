import { isBrowser, toKebabCase } from '@websolutespa/bom-core';
import { LlmTheme, LlmVars } from '../types';

function collectNameAndValues(object: {}, parentKey = '', collection: {
  names: string[];
  values: (string | number)[];
} = { names: [], values: [] }) {
  if (object) {
    Object.entries(object).forEach(([key, value]) => {
      key = (parentKey.length ? `${parentKey}-` : '') + toKebabCase(key);
      if (value && typeof value === 'object') {
        collection = collectNameAndValues(value, key, collection);
      } else if (typeof value === 'string' || typeof value === 'number') {
        collection.names.push(key);
        collection.values.push(value);
      }
    });
  }
  return collection;
}

export function getVars(theme: LlmTheme, namespace: string = 'llm') {
  const nameAndValues = collectNameAndValues(theme);
  const vars: LlmVars = {};
  nameAndValues.names.forEach((key, i) => {
    const value = nameAndValues.values[i];
    vars[`--${namespace}-${key}`] = String(value);
  });
  return vars;
}

export function createVars(vars: LlmVars) {
  if (isBrowser) {
    const style = `<style id="llm-style">
    /**
     * websolute - llm generated vars
     */
    .llm {
${Object.entries(vars).map(([k, v]) => `
      ${k}: ${v};`).join('')}
    }
    </style>`;
    const styleNode = document.querySelector('#llm-style');
    if (styleNode !== null) {
      styleNode.remove();
    }
    document.head.insertAdjacentHTML('beforeend', style);
  }
}
