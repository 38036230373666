import { DeepPartial, deepMerge } from '@websolutespa/bom-core';
import { LlmTheme } from '../types';
import theme from './theme.json';

export const defaultTheme: typeof theme & Record<string, any> = theme;

export function getTheme(...overrides: (DeepPartial<LlmTheme> | undefined)[]) {
  let theme = { ...defaultTheme };
  overrides.forEach(override => {
    theme = deepMerge(theme, { ...override });
  });
  return theme;
}
