import React from 'react';
import { createGenericContext } from '../useGenericContext/useGenericContext';

export type IExtra = Record<string, any>;

const [useExtra, ExtraContextProvider] = createGenericContext<IExtra>({});

function ExtraProvider({ children, extra }: { children?: React.ReactNode, extra: IExtra }) {
  return (
    <ExtraContextProvider value={extra}>
      {children}
    </ExtraContextProvider>
  );
}

export { ExtraProvider, useExtra };

