export function fileToBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = reject;
  });
}

export function fileToResizedBase64(file: File, maxSize: number = 256): Promise<string> {
  // console.log('fileToResizedBase64', file, maxSize);
  return new Promise((resolve, reject) => {
    if (!file.type.match(/image.*/)) {
      reject('not a valid image file');
    }
    // Load the image
    const reader = new FileReader();
    reader.onload = function (readerEvent) {
      if (readerEvent?.target?.result) {
        const image = new Image();
        image.onload = function (imageEvent) {
          const canvas = document.createElement('canvas');
          let width = image.width;
          let height = image.height;
          if (width > height) {
            if (width > maxSize) {
              height *= maxSize / width;
              width = maxSize;
            }
          } else {
            if (height > maxSize) {
              width *= maxSize / height;
              height = maxSize;
            }
          }
          canvas.width = width;
          canvas.height = height;
          const context = canvas.getContext('2d');
          if (context) {
            context.drawImage(image, 0, 0, width, height);
            const dataUrl = canvas.toDataURL('image/jpeg');
            // const resizedImage = dataURLToBlob(dataUrl);
            resolve(dataUrl);
          } else {
            reject('unable to create canvas 2d context');
          }
        };
        image.src = readerEvent.target.result as string;
      } else {
        reject(`unable to read file ${file.name}`);
      }
    };
    reader.readAsDataURL(file);
  });
}

function dataURLToBlob(dataURL: string) {
  const BASE64_MARKER = ';base64,';
  if (dataURL.indexOf(BASE64_MARKER) == -1) {
    const parts = dataURL.split(',');
    const contentType = parts[0].split(':')[1];
    const raw = parts[1];
    return new Blob([raw], { type: contentType });
  }
  const parts = dataURL.split(BASE64_MARKER);
  const contentType = parts[0].split(':')[1];
  const raw = window.atob(parts[1]);
  const rawLength = raw.length;
  const uInt8Array = new Uint8Array(rawLength);
  for (let i = 0; i < rawLength; ++i) {
    uInt8Array[i] = raw.charCodeAt(i);
  }
  return new Blob([uInt8Array], { type: contentType });
}
