import { ReactNode, cloneElement, isValidElement } from 'react';

export const clamp = (min: number, max: number) => Math.min(Math.max(min + (window.innerWidth - 375) / (1920 - 375) * (max - min), min), max);

type NewLineToBrProps = {
  text?: string
};

export const NewLineToBr = (props: NewLineToBrProps) => {
  const lines = props.text?.split('\n').map(function (line, n) {
    return (n == 0) ? [
      <span key={`line-${n}`} dangerouslySetInnerHTML={{ __html: line }} />,
    ] : [<br key={`line-${n * 2}`} />, <span key={`line-${n * 2 + 1}`} dangerouslySetInnerHTML={{ __html: line }} />];
  });
  return lines;
};

export const regExpMapper = (
  text: string | undefined,
  dict: Record<string, (text: string) => ReactNode>,
  rest: (text: string) => ReactNode = (text) => text
): ReactNode | undefined => {
  if (!text) {
    return;
  }
  const find = new RegExp(`(${Object.keys(dict).join(')|(')})|(.+)`, 'g');
  const replacers = Object.values(dict);
  const nodes: ReactNode[] = [];
  text.replace(find, (...matches) => {
    const index = matches.findIndex((v, i) => i > 0 && v !== undefined);
    const value = matches[index];
    const replace = replacers[index - 1];
    let mappedValue: ReactNode = '';
    if (typeof replace === 'function') {
      mappedValue = replacers[index - 1](value);
    } else {
      mappedValue = rest(value);
    }
    const element = isValidElement(mappedValue) ? cloneElement(mappedValue, { key: nodes.length, ...mappedValue.props }) : mappedValue;
    nodes.push(element);
    return '';
  });
  return nodes;
};

export const messageMapper = (text: string | undefined): ReactNode => {
  return regExpMapper(text, {
    '\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff]':
      (text) => <span aria-hidden>{text}</span>,
    '\n':
      () => <br />,
  }, (text) => <span>{text}</span>);
};
