import { getClassNames, isBrowser } from '@websolutespa/bom-core';
import { useLabel } from '@websolutespa/bom-mixer-hooks';
import { IconLlmPin, IconLlmTimetable } from '@websolutespa/bom-mixer-icons';
import { LlmChunkPoi, LlmChunkPoiItem } from '../../../types';
import { useLlm } from '../../../useLlm/useLlm';
import { useLlmView } from '../../../useLlm/useLlmView';
import { Cta } from '../Cta/cta';

export const Poi = (props: LlmChunkPoiItem | LlmChunkPoi) => {
  const label = useLabel();
  const { decorateUrl } = useLlm(state => state.actions);
  const { send } = useLlmView(state => state.actions);
  const onTellMeMore = async (event: React.MouseEvent) => {
    const prompt = `${label('llm.tellMeMoreAbout')} ${props.title}`;
    await send(prompt, (response) => {
      // console.log('Prompt.onChunk', response.chunks);
    }, (response) => {
      // console.log('Prompt.onEnd', response);
    });
  };
  const onGoToWebsite = async (event: React.MouseEvent) => {
    if (isBrowser) {
      event.preventDefault();
      const url = await decorateUrl(props, props.url);
      window.open(url, '_blank');
    }
  };
  const classNames = getClassNames('llm__poi', { [`llm__poi--${props.type}`]: !!props.type });
  return (
    <div className={classNames}>
      {props.image && (
        <div className="llm__poi-media">
          <img src={props.image} alt="" />
        </div>
      )}
      <div className="llm__poi-content">
        {props.title && (
          <div className="llm__poi-title" dangerouslySetInnerHTML={{ __html: props.title }} />
        )}
        {props.abstract && (
          <div className="llm__poi-body" dangerouslySetInnerHTML={{ __html: props.abstract }} />
        )}
        {(props.time || props.place) && (
          <div className="llm__poi-tags">
            {props.time && (
              <div className="llm__poi-tag llm__poi-time"><IconLlmTimetable />{props.time}</div>
            )}
            {props.place && (
              <div className="llm__poi-tag llm__poi-location"><IconLlmPin />{props.place}</div>
            )}
          </div>
        )}
        <div className="llm__poi-ctas">
          <Cta type="button" label={label('llm.tellMeMore')} onClick={onTellMeMore} />
          <Cta type="text" label={label('llm.goToWebsite')} onClick={onGoToWebsite} />
        </div>
      </div>
    </div>
  );
};

