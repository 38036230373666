import { useLabel } from '@websolutespa/bom-mixer-hooks';
import { IconLlmInfoCircle } from '@websolutespa/bom-mixer-icons';
import { useLlm } from '../../../useLlm/useLlm';

type DisclaimerProps = {
};

export const Disclaimer = (props: DisclaimerProps) => {
  const label = useLabel();
  const app = useLlm(state => state.app);
  const appDisclaimer = app?.contents.disclaimer;
  const disclaimer = appDisclaimer || label('llm.disclaimer');
  return disclaimer && (
    <div className="llm__row">
      <div className="llm__col --end-xs-12 --xs-10 --sm-9 --md-8 --lg-7 --xl-6">
        <small className="llm__disclaimer">
          <i><IconLlmInfoCircle /></i>
          <div dangerouslySetInnerHTML={{ __html: disclaimer }} />
        </small>
      </div>
    </div>
  );
};
