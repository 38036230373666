type Listener = (...args: any[]) => void;
type Events<T extends string> = Record<T, Listener[]>;

export class Emitter<T extends string> {
  private readonly events: Partial<Events<T>> = {};

  constructor() {
  }

  public on(event: T, listener: Listener): () => void {
    if (!this.events[event]) {
      this.events[event] = [];
    }
    this.events[event]!.push(listener);
    return () => {
      this.off(event, listener);
    };
  }

  public off(event: T, listener: Listener): void {
    if (!this.events[event]) {
      return;
    }
    const idx: number = this.events[event]!.indexOf(listener);
    if (idx > -1) {
      this.events[event]!.splice(idx, 1);
    }
  }

  public clear(): void {
    Object.keys(this.events).forEach((event: string) => {
      const key = event as T;
      this.events[key]!.splice(0, this.events[key]!.length);
    });
  }

  public emit(event: T, ...args: any[]): void {
    if (!this.events[event]) {
      return;
    }
    [...this.events[event]!].forEach((listener) => listener.apply(this, args));
  }

  public once(event: T, listener: Listener): void {
    const remove: (() => void) = this.on(event, (...args: any[]) => {
      remove();
      listener.apply(this, args);
    });
  }
}
