import { isArray, isObject } from './typeGuards';

/**
 * Represents a primitive value that can be used in the deep merge operation.
 */
export type Primitive = ((...args: any[]) => any) | string | number | boolean | null;

/**
 * Represents a dynamic value that can be used in the deep merge operation.
 */
export type Dynamic = { [key: string]: Dynamic } | Dynamic[] | Primitive;

/**
 * Deeply merges two objects or arrays.
 *
 * @param target - The target object or array to merge into.
 * @param source - The source object or array to merge from.
 * @returns The merged object or array.
 */
export function deepMerge<T extends Dynamic, R extends Dynamic = T>(target?: Partial<T>, source?: Partial<R>): T & R {
  const output = { ...target };
  if (isObject(target) && isObject(source)) {
    Object.entries(source).forEach(([k, v]) => {
      if (isArray(v)) {
        (output as { [key: string]: Dynamic })[k] = (v as Dynamic[]).map(x => deepMerge(x, x));
      } else if (isObject(v)) {
        const targetValue = k in target ? target[k as keyof typeof target] : undefined;
        (output as { [key: string]: Dynamic })[k] = isObject(targetValue) ? deepMerge(targetValue, v) : deepMerge(v, v);
      } else {
        (output as { [key: string]: Dynamic })[k] = v as Primitive;
      }
    });
  }
  return output as T & R;
}
