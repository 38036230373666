import { getClassNames, isBrowser } from '@websolutespa/bom-core';
import { ReactNode, useEffect, useImperativeHandle, useRef } from 'react';
import { useLlm } from '../../../useLlm/useLlm';
import { ScrollerRef, useLlmView } from '../../../useLlm/useLlmView';
import { Emitter } from './emitter';

export const Scroller = ({ children }: { children: ReactNode }) => {
  const innerRef = useRef<HTMLDivElement>(null);
  const scrollerRef = useRef<ScrollerRef & Emitter<'scroll'>>(null);
  const app = useLlm(state => state.app);
  const introed = useLlmView(state => state.introed);
  const { setScroller } = useLlmView(state => state.actions);
  const allowScroll = (!app?.contents.customIntro || introed);
  useEffect(() => {
    if (innerRef.current) {
      if (!allowScroll) {
        innerRef.current.scrollTo(0, 0);
      }
    }
  }, [allowScroll]);
  useImperativeHandle(scrollerRef, () => {
    const emitter = new Emitter<'scroll'>();
    const ref = Object.assign(emitter, {
      scrollTo: (target, options) => {
        if (innerRef.current) {
          const position = { x: 0, y: 0 };
          if (typeof target === 'number') {
            position.y = target;
          } else if (typeof target === 'string') {
            switch (target) {
              case 'top':
                position.y = 0;
                break;
              case 'bottom':
                position.y = innerRef.current.scrollHeight;
                break;
            }
          } else {
            // !!! gestire element
          }
          innerRef.current.scrollTo(position.x, position.y);
        }
      },
      start: () => {
        /*
        if (innerRef.current) {
        }
        */
      },
      stop: () => {
        /*
        if (innerRef.current) {
        }
        */
      },
    } as ScrollerRef) as Emitter<'scroll'> & ScrollerRef;
    setScroller(ref);
    return ref;
  }, [setScroller]);
  useEffect(() => {
    if (isBrowser && innerRef.current && scrollerRef.current) {
      const element = innerRef.current;
      const scroller = scrollerRef.current;
      let lastY = 0;
      const onScroll = () => {
        const limit = element.scrollHeight - element.offsetHeight;
        const targetScroll = element.scrollTop;
        const direction = targetScroll > lastY ? 1 : targetScroll === lastY ? 0 : -1;
        scroller.emit('scroll', {
          limit,
          targetScroll,
          direction,
        });
        lastY = targetScroll;
      };
      element.addEventListener('scroll', onScroll);
      return () => {
        element.removeEventListener('scroll', onScroll);
      };
    }
    return () => { };
  }, []);
  const classNames = getClassNames('llm__scroller', { '-scroller-disabled': !allowScroll });
  return (
    <div ref={innerRef} className={classNames}>
      {children}
    </div>
  );
};
