import React from 'react';
import { LlmChunkItem } from '../../../types';
import { Card } from '../Card/card';
import { CardGroup } from '../CardGroup/card-group';
import { Event } from '../Event/event';
import { EventGroup } from '../EventGroup/event-group';
import { FormRecap } from '../FormRecap/FormRecap';
import { FormRecapError } from '../FormRecapError/FormRecapError';
import { FormRecapSuccess } from '../FormRecapSuccess/FormRecapSuccess';
import { FormRequest } from '../FormRequest/FormRequest';
import { Poi } from '../Poi/poi';
import { PoiGroup } from '../PoiGroup/poi-group';
import { Product } from '../Product/product';
import { ProductGroup } from '../ProductGroup/product-group';
import { StreamError } from '../StreamError/StreamError';
import { Text } from '../Text/text';
import { TripAdvisorGroup } from '../Tripadvisor/tripadvisor';

export type ChunkProps = {
  item: LlmChunkItem;
  chunkIndex: number;
  index: number;
  total: number;
  role: string;
};

export const Chunk: React.FC<ChunkProps> = ({
  item,
  chunkIndex,
  index,
  total,
  role,
}: ChunkProps) => {
  switch (item.type) {
    case 'string': {
      return item.content.length > 0 && (
        role === 'user' ? (
          <div className="llm__row">
            <div className="llm__col --end-xs-12 --xs-10 --sm-9 --md-8 --lg-7 --xl-6">
              <Text body={item.content} chunkIndex={chunkIndex} index={index} />
            </div>
          </div>
        ) : (
          <div className="llm__row">
            <div className="llm__col --sm-10 --sm-9 --md-8 --lg-7 --xl-6">
              <Text body={item.content} chunkIndex={chunkIndex} index={index} />
            </div>
          </div>
        )
      );
    }
    case 'error': {
      return (
        <StreamError {...item} />
      );
    }
    case 'card':
    case 'cardItem': {
      return (
        <Card {...item} />
      );
    }
    case 'cardGroup': {
      return (
        <CardGroup {...item} />
      );
    }
    case 'product':
    case 'productItem': {
      return (
        <Product {...item} />
      );
    }
    case 'productGroup': {
      return (
        <ProductGroup {...item} />
      );
    }
    case 'event':
    case 'eventItem': {
      return (
        <Event {...item} />
      );
    }
    case 'eventGroup': {
      return (
        <EventGroup {...item} />
      );
    }
    case 'poi':
    case 'poiItem': {
      return (
        <Poi {...item} />
      );
    }
    case 'poiGroup': {
      return (
        <PoiGroup {...item} />
      );
    }
    case 'tripadvisorGroup': {
      return (
        <TripAdvisorGroup {...item} />
      );
    }
    case 'formRequest': {
      return (
        <FormRequest last={index === total - 1} />
      );
    }
    case 'formRecap': {
      return (
        <FormRecap />
      );
    }
    case 'formRecapSuccess': {
      return (
        <FormRecapSuccess />
      );
    }
    case 'formRecapError': {
      return (
        <FormRecapError />
      );
    }
    case 'image': {
      return (
        <img src={item.src as string} alt="" />
      );
    }
    default: {
      return (
        <code>{JSON.stringify(item)}</code>
      );
    }
  }
};
