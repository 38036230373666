import { IClassName, IClassNameCollection } from '../types';

/**
 * Checks if the given value is an instance of IClassNameCollection.
 * @param value - The value to check.
 * @returns True if the value is an instance of IClassNameCollection, false otherwise.
 */
function isClassNameCollection(value: IClassName): value is IClassNameCollection {
  return typeof value === 'object' && !Array.isArray(value);
}

/**
 * Converts an IClassNameCollection object to a string of class names.
 * @param className - The IClassNameCollection object.
 * @returns A string of class names.
 */
function classNameCollectionToString(className: IClassNameCollection) {
  const keys = Object.keys(className);
  const total = keys.length;
  let classNames = '';
  for (let i = 0; i < total; i++) {
    const key = keys[i];
    const value = className[keys[i]];
    if (!value) {
      continue;
    }
    classNames = classNames ? `${classNames} ${String(key)}` : String(key);
  }
  return classNames;
}

/**
 * Combines multiple IClassName objects into a single string of class names.
 * @param props - The IClassName objects to combine.
 * @returns A string of class names.
 */
export function getClassNames(...props: Array<IClassName>): string {
  const total = props.length;
  let classNames = '';
  if (total === 0) {
    return classNames;
  }
  for (let i = 0; i < total; i++) {
    const value = props[i];
    if (!value) {
      continue;
    }
    if (isClassNameCollection(value)) {
      classNames += ` ${classNameCollectionToString(value)}`;
    } else {
      classNames += ` ${String(value).trim()}`;
    }
  }
  return classNames.trim();
}
