import { useWindowSize } from '@websolutespa/bom-mixer-hooks';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { LlmCard } from '../../../types';
import { useLlm } from '../../../useLlm/useLlm';
import { useLlmView } from '../../../useLlm/useLlmView';
import { clamp } from '../../utils';

export const Hints = () => {
  const { width } = useWindowSize();
  const app = useLlm(state => state.app);
  const { send } = useLlmView(state => state.actions);

  const swiperProps = {
    spaceBetween: clamp(8, 16),
    slidesOffsetAfter: clamp(20, 110),
    slidesOffsetBefore: clamp(20, 110),
    slidesPerView: clamp(1.2, 2.2),
  };

  const onClick = async (item: LlmCard) => {
    await send(item.message || item.title, (response) => {
      // console.log('QuickLinks.onMessage', response.chunks);
    }, (response) => {
      // console.log('QuickLinks.onEnd', response);
    });
  };

  const getCard = (item: LlmCard, index: number) => {
    return (
      <button type="button" key={`card-${index}`} className="llm__hint" onClick={() => onClick(item)}>
        <div className="llm__hint-title -llm-t-label1">
          {item.icon && (
            <i><img src={item.icon.src || item.icon.url} alt="" /></i>
          )}
          {item.title}
        </div>
        {item.body && (
          <div className="llm__hint-body -llm-t-body3" dangerouslySetInnerHTML={{ __html: item.body }} />
        )}
      </button>
    );
  };

  const getCards = () => {
    if (width < 768) {
      return (
        <Swiper {...swiperProps}>
          {app?.contents.layoutBuilder.map((item, j) =>
            <SwiperSlide key={j}>{getCard(item, j)}</SwiperSlide>
          )}
        </Swiper>
      );
    } else {
      return app?.contents.layoutBuilder.map(getCard);
    }
  };

  return (
    <section className="llm__hints">
      {getCards()}
    </section>
  );
};
