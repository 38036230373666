import { MutableRefObject, useState } from 'react';
import { useIsomorphicLayoutEffect } from '../useIsomorphicLayoutEffect/useIsomorphicLayoutEffect';

export function useFocusTrap<T extends HTMLElement>(
  ref: MutableRefObject<T | null>,
  options: {
    disabled?: boolean,
    selectors?: string,
  } = {}
): [boolean, (value: boolean) => void] {
  const selectors = options.selectors || 'a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])';
  const [state, setState] = useState<boolean>(!options.disabled);
  useIsomorphicLayoutEffect(() => {
    const element = ref.current;
    if (element) {
      const elements = element.querySelectorAll<HTMLElement>(selectors);
      const first = elements[0];
      const last = elements[elements.length - 1];
      const onKeyDown = (event: KeyboardEvent) => {
        // console.log(event.key, event.code, event.keyCode);
        const isTabPressed = (event.key === 'Tab');
        if (!isTabPressed) {
          return;
        }
        if (event.shiftKey) {
          // shift + tab
          if (document.activeElement === first) {
            last.focus();
            event.preventDefault();
          }
        } else {
          // tab
          if (document.activeElement === last) {
            first.focus();
            event.preventDefault();
          }
        }
      };
      element.addEventListener('keydown', onKeyDown);
      return () => {
        element.removeEventListener('keydown', onKeyDown);
      };
    }
    return () => { };
  }, [ref.current]);
  return [state, setState];
}
