import { defaultLocale } from '../locale/locale';
import { ILocaleId, ILocalizable, ILocalizedString } from '../types';
import { isArray, isObject } from '../utils';

const DEFAULT_LOCALE = defaultLocale;

/**
 * Checks if a value is a localized string.
 * @param value - The value to check.
 * @returns A boolean indicating whether the value is a localized string.
 */
export function isLocalizedString(value: any): value is ILocalizedString {
  let isLocalizedString = false;
  if (value && !Array.isArray(value) && typeof value === 'object') {
    const matchKeys = Object.keys(value).reduce((p, c) => p && /^(\w{2})(-\w{2})?$/.test(c), true);
    const matchValues = Object.values(value).reduce((p, c) => p && (typeof c === 'string' || !c), true);
    // console.log(matchKeys, matchValues);
    isLocalizedString = Boolean(matchKeys && matchValues);
  }
  return isLocalizedString;
}

/**
 * Converts a localized string object to a string based on the specified locale.
 * @param json - The localized string object.
 * @param locale - The locale to use.
 * @param defaultLocale - The default locale to use if the specified locale is not found.
 * @returns The localized string.
 */
export function localizedToString(json: ILocalizedString, locale: string, defaultLocale: string = DEFAULT_LOCALE): string {
  const localizedString = json[locale as ILocaleId] || json[defaultLocale as ILocaleId] || Object.values(json)[0];
  return localizedString;
}

/**
 * Localizes a value based on the specified locale.
 * @param value - The value to localize.
 * @param locale - The locale to use.
 * @param defaultLocale - The default locale to use if the specified locale is not found.
 * @returns The localized value as a string.
 */
export function localize(value: ILocalizable, locale: string, defaultLocale: string = DEFAULT_LOCALE): string {
  return isLocalizedString(value) ? localizedToString(value, locale, defaultLocale) : value;
}

/**
 * Localizes a value or an array/object of values based on the specified locale.
 * @param value - The value or array/object of values to localize.
 * @param locale - The locale to use.
 * @param defaultLocale - The default locale to use if the specified locale is not found.
 * @returns The localized value or array/object of localized values.
 */
export function localizeValue(value: any, locale: string, defaultLocale: string = DEFAULT_LOCALE): any {
  if (value) {
    if (isLocalizedString(value)) {
      return localizedToString(value, locale, defaultLocale);
    } else {
      return localizeItem(value, locale, defaultLocale);
    }
  }
}

/**
 * Localizes an array/object of values based on the specified locale.
 * @param item - The array/object of values to localize.
 * @param locale - The locale to use.
 * @param defaultLocale - The default locale to use if the specified locale is not found.
 * @returns The localized array/object of values.
 */
export function localizeItem<T = any>(item: T, locale: string, defaultLocale: string = DEFAULT_LOCALE): T {
  if (isArray(item)) {
    item = item.map(x => localizeValue(x, locale, defaultLocale)) as T;
  } else if (isObject(item)) {
    item = { ...item };
    const object = item as Record<string, unknown>;
    Object.keys(object).forEach(key => {
      object[key] = localizeValue(object[key], locale, defaultLocale);
    });
  }
  return item;
}
