export type EventCallback = (...data: any[]) => void;

export class Emitter {
  events = new Map<string, EventCallback[]>();

  on(event: string, callback: EventCallback) {
    const callbacks = this.events.get(event) || [];
    callbacks.push(callback);
    if (!this.events.has(event)) {
      this.events.set(event, callbacks);
    }
  }

  off(event: string, callback: EventCallback) {
    if (this.events.has(event)) {
      const callbacks = this.events.get(event)!.filter((c) => c !== callback);
      this.events.set(event, callbacks);
    }
  }

  emit(event: string, ...data: unknown[]) {
    if (this.events.has(event)) {
      this.events.get(event)!.forEach((callback) => {
        callback(...data);
        // setTimeout(() => callback(...data), 0);
      });
    }
  }
}
